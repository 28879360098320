@import '../../assets/sass/app.scss';

    main#forgot-password {

        width:   100%;
        height:  fit-content;

        display: flex;
        padding: 0 0 100px 0;

        flex-direction:  column;
        align-items:     center;
        justify-content: center;

        position:           relative;
        background-color: $lightGray;

        figure { 
            @include cover(250px, auto); 
            
            margin: 25px auto; 
            cursor:   pointer;
        }
        .box {
            background-color: $white;
            
            border-radius: 5px;
            position: relative;

            form {
                margin: 50px auto;

                display:   flex;
                flex-flow: row wrap;

                align-items:     flex-start;
                justify-content: flex-start;
                
                h1 {
                    font-weight:   400;
                    font-size:  2.4rem;
                    text-align: center;
                }
                label {
                    font-weight: 700;
                    font-size: .85em;
                }
                button {
                    padding: 7px 15px;
                }
                .form-group {
                    width:  100%;
                    margin: 10px 0;
                }
                .description {
                    width:   90%;
                    margin:  0 auto 30px;

                    font-weight: 300;
                    font-size: .9rem;
                    
                    color:   $darkGray;
                    text-align: center;
                }
                .actions {
                    width:     90%;
                    margin: 0 auto;
                    display:  flex;
                    
                    align-items: center;
                    button { margin: auto; }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        main#forgot-password { 
            .actions { flex-direction: column; }
            .box { 
                width:   90%; 
                padding: 60px 15px;

                form { width: 80%; .column { width: 100%; }}
            }
        }
    }

    @include media-breakpoint-up(lg) {
        main#forgot-password { 
            .actions { flex-direction: row; }
            .box { 
                width:    75%;
                padding: 60px;
                form { width: 60%; .column { width: 50%; }}
            }
        }
    }