
    /**
 * Esse arquivo é para a inclusão de todas funções pré dispostas para o site.
 *
 * Analista/Desenvolvedor: Vento Macedo
 * Contato:                vento@tadah.xyz
 * Data:                   13.12.2017
 **/
 
    /** Placeholders **/
    @mixin placeholder {
        &.placeholder                  { @content; }
        &:-moz-placeholder             { @content; }
        &::-moz-placeholder            { @content; }
        &:-ms-input-placeholder        { @content; }
        &::-webkit-input-placeholder   { @content; }
    }

    /** Figures **/
        @mixin cover($width, $height: auto) {
            width:  $width;
            height: $height;

            overflow: hidden;
            
            margin:  0 auto;
            padding: 0;
            
            & > img { display: block; width: 100%; height: 100%; object-fit: cover; object-position: center; }
        }
        @mixin contain($width, $height: auto) {
            width:  $width;
            height: $height;

            overflow: hidden;
            
            margin:  0 auto;
            padding: 0;
            
            & > img { display: block; width: 100%; height: 100%; object-fit: contain; object-position: center; }
        }

    /** Hardware Aceleration **/
    @mixin hardware($backface: true, $perspective: 1000) {
        @if $backface
        {
            backface-visibility: hidden;
        }
        perspective: $perspective;
    }

    /** Truncade **/
    @mixin truncate($truncation-boundary) {
        max-width:     $truncation-boundary;
        white-space:   nowrap;
        overflow:      hidden;
        text-overflow: ellipsis;
    }

    /** Face Font **/
    @mixin font-face($name, $path, $exts: eot woff2 woff ttf svg ttc, $weight: null, $style: null) {
        $src: null;

        $extmods: ( ceot: "?",
                    svg: "#" + str-replace($name, " ", "_") );

        $formats: ( otf: "opentype",
                    ttf: "truetype" );

        @each $ext in $exts {
            $extmod:  if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
            $format:  if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
            $src:     append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
        }

        @font-face
        {
            font-family: quote($name);
            font-style:  $style;
            font-weight: $weight;
            src:         $src;
        }
    }

    /** Clear Fix **/
    @mixin clearfix {
        &:after,
        &:before {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    /** Events **/
    @mixin on-event($self: false) {
		@if $self {
			&,
			&:hover,
			&:active,
			&:focus { @content; }
		}
		@else {
			&:hover,
			&:active,
			&:focus { @content; }
		}
    }
    
    /** Transitions **/
    @mixin transition() {
        -webkit-transition: all .5s ease-in-out;
        -moz-transition:    all .5s ease-in-out;
        -o-transition:      all .5s ease-in-out;
        transition:         all .5s ease-in-out;
    }

    /** Presets **/
    @mixin presets($color: rgba(0,0,0)) {
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing:   subpixel-antialiased;
        -moz-osx-font-smoothing:  grayscale;
        text-rendering:           optimizeLegibility;

        font-family: $font  !important;
        font-weight: $light !important;
        font-size:   16px   !important;
        color:       $color;
    }

    /** Rotate Text **/
    @mixin rotated-text($num-letters: 100, $angle-span: 180deg, $angle-offset: 0deg) {

        $angle-per-char: $angle-span / $num-letters;
        @for $i from 1 through $num-letters {
            .char-#{$i} { transform: rotate($angle-offset + $angle-per-char * $i); }
        }
    }