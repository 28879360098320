.form-component.antd {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 12px;
  * {
    font-family: "MontSerrat", sans-serif;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .label-error {
    align-self: flex-end;
    font-size: 12px;
    color: #f5222d;
    min-height: 20px;
    padding-top: 2px;
  }
  .ant-input {
    overflow: hidden;
    display: flex;
    &:hover {
      border: 1px solid rgb(32, 152, 105) !important;
    }
    align-items: center;
    padding: 4px;
    &:focus {
      box-shadow: 0 0 0 2px rgba(32, 152, 105, 0.2)
    }
  }
}