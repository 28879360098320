@import '../../assets/sass/app.scss';
@import '../../assets/sass/abstracts/colors.scss';
@import '../JackpotModal-Mobile/styles.scss';

.jackpot-modal {
	width: 100%;
	height: 100%;
    min-width: 300px;

    .MuiTabs-root {
        margin-bottom: 40px
    }

    .MuiTabs-indicator {
        background: $green;
    }

    .super-sete-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem 0;
        &:not(:last-child) {
            padding-right: 5px;
            border-right: 1px solid $lightGray;
        }
    }

    .col-number, .col-number-declarative {
        font-size: 12px;
        color: $gray;
    }

    @media (max-width: 1000px) {
        // .col-number-declarative {
        //     display: none !important;
        // }
    }

	.icon-size {
		width: 24px;
	}

	.MuiTypography-body1 {
		font-family: $fontBase;
	}

	.modal-box {
		height: 90vh;
        width: 100%;
        @media (max-width: 1000px) {
            height: 100vh;
        }
		padding: 0;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);
		background-color: $silver;

		display: flex;
		flex-direction: column;

		align-items: center;
		justify-content: center;

		border-radius: 5px;
		overflow: hidden;

		.modal-title {
			width: 100%;
			height: 4rem;

			padding: 5px 15px;
			margin: 0px;

			display: flex;
			flex-direction: row;

			align-items: center;
			justify-content: space-between;

			background-color: $gray;

			.title {
				font-weight: 700;
				font-size: 0.95rem;
				color: $white;

				display: flex;
				align-items: center;

				img {
					margin: 0 10px 0 0;
				}
			}
			.ref {
				font-weight: 300;
				font-size: 1rem;

				display: flex;
				flex-direction: row;
				align-items: center;

				.close-modal {
					margin: 0 0 0 5px;
					color: $white;

					font-size: 1.4rem;
					cursor: pointer;
				}
			}

			&.mega-sena {
				background-color: $megaSena;
				color: $white;
			}
			&.timemania {
				background-color: $timeMania;
				color: $white;
			}
			&.quina {
				background-color: $quina;
				color: $white;
			}
			&.lotofacil {
				background-color: $lotoFacil;
				color: $white;
			}
			&.lotomania {
				background-color: $lotoMania;
				color: $white;
			}
			&.loteca {
				background-color: $loteca;
				color: $white;
			}
			&.dupla-sena {
				background-color: $duplaSena;
				color: $white;
			}
			&.dia-de-sorte {
				background-color: $diaDeSorte;
				color: $white;
			}
			&.super-sete {
				background-color: $superSete;
				color: $white;
			}
			&.mais-milionaria {
				background-color: $maisMilionaria;
				color: $white;
			}
		}
		.modal-body {
			overflow-x: hidden;
			overflow-y: auto;
            min-width: 370px;

			width: 100%;
			height: 95%;

			h1 {
				font-weight: 900;
				font-size: 1rem;

				&.mega-sena {
					color: $megaSena;
				}
				&.timemania {
					color: $timeMania;
				}
				&.quina {
					color: $quina;
				}
				&.lotofacil {
					color: $lotoFacil;
				}
				&.lotomania {
					color: $lotoMania;
				}
				&.loteca {
					color: $loteca;
				}
				&.dupla-sena {
					color: $duplaSena;
				}
				&.dia-de-sorte {
					color: $diaDeSorte;
				}
				&.super-sete {
					color: $superSete;
				}
				&.mais-milionaria {
					color: $maisMilionaria;
				}
			}

			.top {
				.tab-list {
					display: flex;
					flex-direction: row;
					justify-content: space-evenly;

                    &.tab-reverse {
                        .MuiTabs-flexContainer {
                            flex-direction: row-reverse;
                        }
                    }

					width: 100%;

					.tab-item {
                        padding: 1rem 3rem;
                        border-bottom: 1px solid $lightGray;
                      
                        list-style: none;
                                    text-transform: none !important;
                                    font-size: 16px;
                                    color: $black !important;
                        &:hover {
                          cursor: pointer;
                        }
                      
                        &.active {
                          font-weight: bold !important;
                        }

                        @media (max-width: 1000px) {
                            padding: 1rem;
                        }
                      }

					.selected {
						font-weight: bold;
					}
				}
			}

			.first-tab {
				.list {
					display: flex;
					flex-direction: column;
					gap: 0.1rem;

					padding: 0;

					list-style: none;
					width: 100%;

					&.primary-list {
						margin-top: 3rem;

						span:first-child {
							display: flex;
							align-items: center;
							grid-gap: 1rem;
							gap: 1rem;
						}

						.caixa-result {
							display: flex;
							flex-direction: row;
							gap: 1rem;
							flex-wrap: wrap;
                            align-items: start !important;

							span.number {
								width: 2rem;
								height: 2rem;
								padding: 0.4rem;
								border-radius: 50%;
                                color: $white;

								background: $black;

								text-align: center;
							}


							.complement {
								width: auto !important;

								margin: 0 0 0 5px !important;
								padding: 7px !important;
								min-width: 20px;

								display: flex;
								flex-direction: column;

								align-items: center;
								justify-content: center;

								background-color: $black;
								color: $white;

								border-radius: 7px !important;
								text-align: center;

								font-size: 0.8rem;

								@include transition;
								cursor: default;

								text-transform: uppercase;

								&.active {
									background: $black;
									color: $white;
								}
							}
						}

						li:first-child {
							span {
								display: flex;
								align-items: center;
								gap: 1rem;
							}
						}

						li {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;

							padding: 0.6rem;

							background: $white;
						}
					}

					&.secondary-list {
						margin-top: 0;
						li {
							display: flex;
							flex-direction: row;
							justify-content: space-between;

							padding: 0.6rem;

							background: $lightGray;
						}
					}

					&.historic-list {
						margin-top: 3rem;

						.caixa-result {
							display: flex;
							flex-direction: row;
							gap: 1rem;

							color: $white;

							span.number {
								width: 2rem;
								height: 2rem;
								padding: 0.4rem;
								border-radius: 50%;

								background: $black;

								text-align: center;
							}
						}

						li {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;

							padding: 0.6rem;

							background: $white;
						}
					}
				}

				.receipt-button {
					display: flex;
					gap: 1rem;
					justify-content: center;
					align-items: center;

					margin: 1.5rem 0;

					width: 30%;

					background: $green;
					color: $white;
				}

				.description {
					margin: 1rem;
				}

				.games {
					display: flex;
					flex-direction: column;
					justify-content: left;
					gap: 0.5rem;

					.game {
						display: flex;
						flex-direction: row;
						justify-content: left;
						flex-wrap: wrap;

						span {
							margin-left: 1rem;
						}
					}
				}

				#see-more {
					font-size: 1rem;
					color: $green;
					text-decoration: underline;
					margin-left: 1rem;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.second-tab {
				.search-bar {
					width: 100%;
					margin: 15px 0;

					padding: 15px;
					border-radius: 5px;

					display: flex;
                    align-items: center;
                    flex-direction: row;
					justify-content: space-between;

                    @media (max-width: 1000px) {
                        align-items: flex-start;
                        flex-direction: column;
                    }

					background-color: $lightGray;

					strong {
						font-weight: 700;
						font-size: 0.9rem;
                        @media (max-width: 1000px) {
                            margin-bottom: 10px;
                        }
					}
					.form-group {
						margin: 0;
						position: relative;
                        @media (max-width: 1000px) {
                            width: 100%;
                        }

						button {
							background-color: transparent;
							position: absolute;

							border: 0;
							color: $darkGray;

							cursor: pointer;
							font-size: 0.9rem;

							&.submit {
								top: 5px;
								right: 5px;
							}
							&.clear {
								top: 8px;
								right: 30px;
								color: $gray;
								.icon {
									font-size: 1.2rem;
								}
							}
						}
					}
				}

				ul {
					margin-top: 3rem;
					list-style: none;
                    padding-inline-start: 20px;

					li {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						gap: 2rem;
                        .game-counter {
                            display: flex;
                            align-items: center;
                        }
                        .icon-size-mobile {
                            display: none;
                        }
                        @media (max-width: 1000px) {
                            flex-direction: column;
                            align-items: flex-start;
                            .icon-size {
                                display: none;
                            }
                            .icon-size-mobile {
                                display: block;
                                margin-right: 20px;
                            }
                        }

						padding-bottom: 1rem;
						margin-bottom: 1rem;
						border-bottom: 1px solid $lightGray;

						.award {
							display: flex;
							flex-direction: column;
							gap: 0.8rem;
							font-size: 0.98rem;

							.award-li {
								display: flex;
								flex-direction: row;
								gap: 0.5rem;
							}
						}

						.game-numbers {
							display: flex;
							flex-direction: column;
							gap: 1rem;
                            flex: 1;

							// width: 100%;
							position: relative;
							flex-wrap: wrap;

							.first-draw,
							.second-draw {
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								@media (max-width: 1000px) {
									flex-direction: column;
									justify-content: flex-start;
									gap: 1rem;
								}
								gap: 2rem;
								font-weight: 500;

								.mais-milionaria {
									width: 32px;
									height: 32px;
									display: flex;
									align-items: center;
									justify-content: center;
								}

								.complement {
									width: auto !important;

									margin: 0 0 0 5px !important;
									padding: 7px !important;
									min-width: 35px;
									min-height: 35px;

									display: flex;
									flex-direction: column;

									align-items: center;
									justify-content: center;

									background-color: $lightGray;
									color: $darkGray;

									border-radius: 7px !important;
									text-align: center;

									font-size: 0.8rem;

									@include transition;
									cursor: default;

									text-transform: uppercase;

									&.active {
										background: $black;
										color: $white;
									}
								}

								.complements {
									display: flex;
									gap: 0.5rem;
									margin-top: 0.5rem;
                  justify-content: center;
								}

								.complement-container {
									display: flex;
									margin-top: 16px;
									align-items: center;
								}

								.info {
									display: flex;
									justify-content: space-between;
									align-items: center;
									span {
										margin-right: 10px;
										white-space: nowrap;
									}
								}

								.game-cols {
									display: flex;
									flex-direction: column;
									flex: 1;
									width: 100%;
									align-content: flex-end;
									justify-content: flex-end;
									flex: 1;
									@media (max-width: 1000px) {
										justify-content: flex-start;
										width: 100%;
									}
									align-items: flex-start;
									flex-wrap: wrap;
								}

								.numbers {
									display: flex;
									flex-direction: row;
									gap: 0.5rem;
									align-items: flex-start;
									flex-wrap: wrap;
									.number {
										width: 2rem;
										height: 2rem;
										padding: 0.4rem;
										border-radius: 50%;

										background: $lightGray;
										color: $black;

										text-align: center;

										&.active {
											background: $black;
											color: $white;
										}
									}
								}
							}
						}
					}
				}
			}

			.third-tab {
				.active-square {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $lightGreen;

				}
				
				.active-square-gray{
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $silver;
				}
				.inative-square {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $silver;
				}

				.inative-square-red {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $red;
				}

				.list {
					display: flex;
					flex-direction: column;
					gap: 0.1rem;

					padding: 0;

					list-style: none;
					width: 100%;

					margin-top: 3rem;

					.caixa-result {
						display: flex;
						flex-direction: row;
						gap: 1rem;

						color: $white;

						span.number {
							width: 2rem;
							height: 2rem;
							padding: 0.4rem;
							border-radius: 50%;

							background: $black;

							text-align: center;
						}
					}

					li {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;

						padding: 0.6rem;

						background: $white;

						span:first-child {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							gap: 1rem;
						}
					}
				}

				.historic-title {
					padding: 1rem;
				}

				.historic-timeline {
					width: 100%;
					margin-right: auto;

					.historic-timeline-date {
						flex: none;
						width: 40%;
					}
				}
			}

			.pagination {
				width: 100%;
				margin: 30px 0 15px;

				display: flex;
				flex-direction: row;

				align-items: center;
				justify-content: center;
			}
			.loading {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;

				background-color: rgba(241, 239, 239, 0.6);

				z-index: 10;
				margin: 0;
				padding: 50px;

				display: flex;
				flex-direction: column;

				align-items: center;
				justify-content: center;

				svg {
					color: $green;
					font-size: 5rem;
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.jackpot-modal {
		.modal-box {
			width: 70vw;
			.modal-body {
				padding: 30px;

				.top {
					flex-direction: row;
					.info {
						// width: 35%;
                        // @
					}
					.buttons {
						width: 40%;
						padding: 0 15px;
					}
				}
				.search-bar {
					align-items: center;
					flex-direction: row;
					.form-group {
						width: 40%;
					}
				}
				.games {
					.line {
						align-items: center;
						flex-wrap: wrap;
						.numbers {
							flex-flow: row wrap;
						}
						.links {
							width: 40%;
							font-size: 0.8rem;
							text-align: right;
						}
					}
				}
			}
		}
	}
}
