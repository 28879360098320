@import '../../assets/sass/app.scss';

   #container-background{
       position: absolute;
       z-index: 999;
       top: 0;
       left: 0;

       width: 100vw;
       height: 100vh;
       background-color: #0c0c0c8f;
   }
   #container-modal-global{
        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        align-self: center;
        z-index: 1000000;
        top: 2rem;
        left: 35vw;

        padding: 1.5rem;

        width: 30rem;
        height: auto;
        border-radius: 0.5rem;

        background-color: $white;
        color: black;

        .btn{
            margin-top: 0.5rem;
            color: $white;
            background-color: $green;

            padding: 1rem 1.5rem 1rem 1.5rem;
            border-radius: 1.5rem;

            text-decoration: none;
        }

        .btn:hover{
            background-color: $lightGreen;
            color: $white;
        }

        &.closed{
            display: none;
        }

       .close{
            width: 1.5rem;

            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
       }

       .title{
           font-size: 1.1rem;
           font-weight: bold;
           text-align: center;
           color: $black;
       }

       .subtitle{
           color: $black;
           text-align: center;

           margin-top: 1rem;
           margin-bottom: 0.5rem;
       }
   }

   
   @include media-breakpoint-down(sm) {
    #container-modal-global {
        width: 19rem;
        height: auto;

        left: 3.5rem;

       
        
    }
}