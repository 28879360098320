@import '../../assets/sass/app.scss';

    main#faq {
        width:  100%;
        margin: 80px auto;

        h1 {
            font-weight:   700;
            width:        100%;
            text-align: center;
        }
        .items {
            display:          flex;
            flex-direction: column;

            align-items:     center;
            justify-content: space-between;

            .item {
                width:  100%;
                margin: auto;
                opacity: 1;
                
                @include transition;

                &.hidden { opacity: 0; }
                &.item-opened {
                    .question {
                        .icon {
                            .horizontal { transition: all .3s ease-in-out; transform: rotate(90deg); opacity: 1; }
                            .vertical   { transition: all .3s ease-in-out; transform: rotate(90deg); opacity: 0; }
                        }
                    }
                    .answer {
                        max-height: 100vh;
                    }
                }
                &:hover {
                    .icon { > * { background-color: $green; }}
                }
                .question {
                    display:  flex;
                    cursor: pointer;

                    width:   100%;
                    padding: 20px 15px;
                    
                    background-color: $white;
                    border-bottom: 1px solid $silver;

                    position:  relative;
                    flex-direction: row;

                    align-items:     center;
                    justify-content: space-between;
                    
                    span {
                        width:       95%;
                        margin-left: 10px;

                        font-weight:  700;
                        font-size:  .9rem;
                        color:     $black;
                    }
                    .icon {
                        width:    5%;
                        position: relative;
                        @include transition;

                        .horizontal {
                            position:        absolute;
                            background-color:   $blue;
                            
                            width:   4px;
                            height: 16px;
                            
                            margin-top:  -9px;
                            margin-left: -3.5px;
                            
                            opacity:     1;

                            top:  50%;
                            left: 50%;

                            transition: all .3s ease-in-out;
                            transform:       rotate(-90deg);
                        }
                        .vertical {
                            @extend .horizontal;
                            
                            width:    16px;
                            height:    4px;

                            margin-top:  -2.5px;
                            margin-left:  -10px;

                            opacity:     1;
                            
                            transition: all .3s ease-in-out;
                            transform:       rotate(-90deg);
                        }
                    }
                }
                .answer {
                    width:  100%;
                    height: 100%;

                    max-height:    0;
                    overflow: hidden;
                    
                    transition: max-height 1s ease-in-out;

                    span {
                        display: inline-block;
                        padding:  20px 15px 45px;

                        font-weight: 400;
                        font-size: .9rem;

                        color:      $gray;
                        line-height:  1.4;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        main#faq {
            h1     { font-size: 1.6rem; }
            .items { width: 90%; margin: 20px auto; }
        }
    }

    @include media-breakpoint-up(lg) {
        main#faq {
            h1     { font-size: 2rem; }
            .items { width: 60%; margin: 30px auto; }
        }
    }