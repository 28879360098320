@import '../../assets/sass/app.scss';

    main#register {
        width:   100%;
        min-height: 100vh;
        height:  fit-content;

        display: flex;
        padding: 0 0 100px 0;

        flex-direction:  column;
        align-items:     center;
        justify-content: center;

        position:           relative;
        background-color: #F4F5F6;

        figure { 
            @include cover(250px, auto); 
            
            margin: 25px auto; 
            cursor:   pointer;
        }
        .box {
            background-color: $white;
            
            border-radius: 16px;
            position: relative;

            min-height: 600px;
            max-width: 800px;

            padding: 32px 100px !important;

            h1 {
                width:      100%;
                font-weight: 700;

                font-size: 1.8rem;
                color:     $black;

                text-align: center;
            }
            form { 
                margin: 50px auto;

                display:   flex;
                flex-flow: row wrap;

                align-items:     flex-start;
                justify-content: flex-start;
                
                .form-group {
                    width:  100%;
                    margin: 10px 0;
                }
                .column {
                    padding: 0 15px;
                }
                .actions {
                    width:   100%;
                    padding: 0 15px;
                    margin:  30px auto;
                }
                .goBack {
                    font-size:      .9rem;
                    text-transform: uppercase;
                }
                .help {
                    font-size: .7rem;
                    text-align: left;
                }
                .aux {
                    font-size: .7rem;
                    text-align: left;
                }
                hr {
                    width:  95%;
                    border:   0;
                    
                    border-top: 1px solid $lightGray;
                    margin:     30px auto;
                }
                label {
                    font-weight: 700;
                    font-size: .8rem;
                }
                button {
                    min-width:  180px;
                    margin: 10px auto;
                }
                small {
                    font-weight: 300;
                    font-size: .8rem;

                    width:        100%;
                    text-align: center;

                    display: block;

                    span {
                        font-weight: 400;
                        font-size: .9rem;
                    }
                }
            }
            .disabled {
                background-color: $silver;
                border-color:  $lightGray;
            }
            .feedback {
                width:  100%;
                margin: 15px auto;
            }
            .eye {
                width: 100%;
                margin:   0;

                display:     block;
                position: relative;

                svg { 
                    position:     absolute;
                    top: 08px; right: 10px;
                    
                    color:    $blue;
                    cursor: pointer;
                }
            }
            .loading {
                width:  100%;
                height: 100%;

                position: absolute;
                top:  0;  left:  0;

                background-color: rgba(255, 255, 255, .5);

                display:          flex;
                flex-direction: column;

                align-items:     center;
                justify-content: center;

                .MuiCircularProgress-colorPrimary { color: $green; }
            }
            .wizard {
                display: none !important;
                width:  300px;
                margin: 30px auto;

                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: center;

                list-style: none;
                padding:       0;

                li {
                    width:  20px;
                    height: 20px;

                    margin:  0;
                    padding: 0;

                    background-color: $lightGray;
                    border-radius:    100%;

                    &.active {
                        background-color: $yellow !important;
                    }
                    &.line {
                        width: 50px;
                        height: 2px;
                        
                        background-color: $lightGray;
                        border-radius:    0;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) { 
        main#register {
            .box { 
                width:   90%; 
                padding: 60px 15px;

                form { width: 100%; .column { width: 100%; }}
            }
        }
    }
    @include media-breakpoint-up(lg)   { 
        main#register {
            .box { 
                width:    75%;
                padding: 60px;
                form { width: 70%; .column { width: 50%; }}
            }
        }
    }