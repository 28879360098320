@import 'abstracts/mixins.scss';
@import 'abstracts/fonts.scss';
@import 'abstracts/colors.scss';

@import "libraries/bootstrap/bootstrap.scss";

@import "libraries/animate";
@import "transitions/fade";
@import "transitions/slide";

html {
    box-sizing: border-box;
}
*, *::before, *::after {
    box-sizing:                             inherit;
    outline:                        none !important;
    -webkit-tap-highlight-color:         $colorBase;
}
.hidden {
    display: none !important;
}

.rs-drawer-header {
    padding: 20px 20px 20px 60px;
}

*:disabled {
    cursor: not-allowed !important;
}

.rs-checkbox {
    margin-left: 0 !important;
    .rs-checkbox-checker {
        padding-left: 24px !important;
    }
    .rs-checkbox-wrapper {
        left: 0 !important;
        &:before {
            // left: -10px !important;
        }
    }
}

.text-button {
    border: none !important;
    background: none !important;
    color: $green !important;
    margin: 8px 0;
}

.rs-tooltip {
    line-height: normal !important;
    padding: 8px !important;
    z-index: 9999 !important;
}

body {
    width:   100vw;
    margin:  0;
    padding: 0;

    font-family:   $fontBase !important;
    color: 		  $colorBase;
    background-color: $white;

    font-size: 	 16px;
    line-height: 1.2em;
    letter-spacing: normal;

    font-weight: 400;
    max-width:   100%;

    top:  0;  left:  0;
    overflow-x: hidden;
    overflow-y:   auto;

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing:   subpixel-antialiased;
    -moz-osx-font-smoothing:  colorBasescale;
    text-rendering:           optimizeLegibility;

    /**
    * Resets
    **/
        a { &:hover { text-decoration: none; }}

        &.no-scroll       { overflow: hidden; }
        &.no-scroll-login { overflow: hidden; }
        &.no-scroll-cart  { overflow: hidden; }
        &.no-scroll-menu  { overflow: hidden; }

        .link {
            cursor: pointer;

            color:           $blue;
            text-decoration: underline;

            @include transition;

            &:hover {
                color: $green;
            }
        }
        .srv-validation-message {
            font-size: .7rem;
            color:      $red;
            padding:   5px 0;
        }
        .form-control {
            font-size: .9rem;
        }

        @include media-breakpoint-down(sm) {
            position: absolute;

            #root { overflow: hidden; }
            main  { padding-top: 90px; }
            .desktop { display:  none; }
            .mobile  { display: block; }
        }

        @include media-breakpoint-up(lg) {
            position: relative;

            main  { padding-top: 90px; }
            .desktop { display: block; }
            .mobile  { display:  none; }
        }

    /**
    /*Global Css
    **/

    .bold{
        font-weight: bolder;
    }

    .font {
        &-100 {font-weight: 100;}
        &-200 {font-weight: 200;}
        &-300 {font-weight: 300;}
        &-400 {font-weight: 400;}
        &-500 {font-weight: 500;}
        &-600 {font-weight: 600;}
        &-700 {font-weight: 700;}
        &-800 {font-weight: 800;}
        &-900 {font-weight: 900;}
    }

    .pair{
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;

        gap:0.5rem;

        .key{
            &::after{
                content: ": ";
            }
        }

        .value{
            font-weight: bold;
        }
    }

		.jackpot-mus-card {
			color: #0B5C3C;
			padding: 8px;
			width: auto;
			margin: 10px auto 0 auto;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 8px;
			font-weight: 500;
			background-color: #FFCC4D;
			border-radius: 10px 10px 0 0;
		}
}

@media (max-width: 767px) {
    .adopt-c-btNtdA {
        inset: auto 0px 170px !important;
    }

    #adopt-controller-button {
        bottom: 100px !important;
    }
}
