.checkbox-symbol {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.6;
  .checkbox {
    .label-box {
      border-color: #cccfdb;
      background-color: #cccfdb;
    }
  }
}

.checkbox-container {

  & * {
    box-sizing: border-box;
  }

  .checkbox-input {
    position: absolute;
    visibility: hidden;
  }

  .checkbox {
    user-select: none;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .checkbox:not(:last-child) {
    margin-right: 6px;
  }

  .checkbox span {
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }

  .checkbox .label-box {
    position: relative;
    flex: 0 0 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.3s ease;
  }

  .checkbox .label-box svg {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 80%;
    height: 80%;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transform: translate(-50%, -50%);
  }

  .checkbox .label-box {
    margin-right: 8px;
  }

  .checkbox p {
    margin-bottom: 0 !important;
  }

  .checkbox:hover .label-box {
    border-color: #209869;
  }

  .checkbox-input:checked+.checkbox .label-box {
    background: #209869;
    border-color: #209869;
    animation: zoom-in-out 0.3s ease;
  }

  .checkbox-input:checked+.checkbox .label-box svg {
    stroke-dashoffset: 0;
  }
}

@keyframes zoom-in-out {
  50% {
    transform: scale(0.9);
  }
}
