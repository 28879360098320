.manutencao.container {
	border-radius: 5px;
	width: 100vw;
	height: 100vh;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.manutencao.container > div {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	padding: 20px;
	border: 2px solid darkgray;
}
