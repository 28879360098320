@import '../../assets/sass/app.scss';

.mobile-jackpot-modal {
	width: 100%;
	height: 100%;

	.icon-size {
		width: 24px;
	}

	.MuiTypography-body1 {
		font-family: $fontBase;
	}

	.mobile-modal-box {
		height: 100vh;
		width: 100vw;
		padding: 0;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);
		background-color: $silver;

		display: flex;
		flex-direction: column;

		align-items: center;
		justify-content: center;

		overflow: hidden;

		.mobile-modal-title {
			width: 100%;
			height: 4rem;

			padding: 5px 15px;
			margin: 0px;

			display: flex;
			flex-direction: row;

			align-items: center;
			justify-content: space-between;

			background-color: $gray;

			.mobile-title {
				font-weight: 700;
				font-size: 0.95rem;
				color: $white;

				display: flex;
				align-items: center;

				img {
					margin: 0 10px 0 0;
				}
			}
			.mobile-ref {
				font-weight: 300;
				font-size: 1rem;

				display: flex;
				flex-direction: row;
				align-items: center;

				.mobile-close-modal {
					margin: 0 0 0 5px;
					color: $white;

					font-size: 1.4rem;
					cursor: pointer;
				}
			}

			&.mobile-mega-sena {
				background-color: $megaSena;
				color: $white;
			}
			&.mobile-timemania {
				background-color: $timeMania;
				color: $white;
			}
			&.mobile-quina {
				background-color: $quina;
				color: $white;
			}
			&.mobile-lotofacil {
				background-color: $lotoFacil;
				color: $white;
			}
			&.mobile-lotomania {
				background-color: $lotoMania;
				color: $white;
			}
			&.mobile-loteca {
				background-color: $loteca;
				color: $white;
			}
			&.mobile-dupla-sena {
				background-color: $duplaSena;
				color: $white;
			}
			&.mobile-dia-de-sorte {
				background-color: $diaDeSorte;
				color: $white;
			}
			&.mobile-super-sete {
				background-color: $superSete;
				color: $white;
			}
			&.mobile-mais-milionaria {
				background-color: $maisMilionaria;
				color: $white;
			}
		}
		.mobile-modal-body {
			overflow-x: hidden;
			overflow-y: auto;

			width: 100%;
			height: 100%;

			h1 {
				font-weight: 900;
				font-size: 1rem;

				&.mobile-mega-sena {
					color: $megaSena;
				}
				&.mobile-timemania {
					color: $timeMania;
				}
				&.mobile-quina {
					color: $quina;
				}
				&.mobile-lotofacil {
					color: $lotoFacil;
				}
				&.mobile-lotomania {
					color: $lotoMania;
				}
				&.mobile-loteca {
					color: $loteca;
				}
				&.mobile-dupla-sena {
					color: $duplaSena;
				}
				&.mobile-dia-de-sorte {
					color: $diaDeSorte;
				}
				&.mobile-super-sete {
					color: $superSete;
				}
				&.mobile-mais-milionaria {
					color: $maisMilionaria;
				}
			}

			.mobile-top {
				.mobile-tab-list {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					gap: 1rem;

					margin: 0;
					padding: 0;

					width: 100%;

					.mobile-tab-item {
						padding: 2rem 1rem;
						border-bottom: 1px solid $lightGray;
						width: 28vw;
						text-align: center;

						list-style: none;

						&:hover {
							cursor: pointer;
						}

						&.active {
							font-weight: bold;
						}
					}

					.selected {
						font-weight: bold;
					}
				}
			}

			.mobile-first-tab {
				.mobile-list {
					display: flex;
					flex-direction: column;
					gap: 0.1rem;

					padding: 0;

					list-style: none;
					width: 100%;

					&.mobile-primary-list {
						margin-top: 1rem;

						.mobile-prize-result {
							padding: 1rem 2rem;

							span:first-child {
								display: flex;
								align-items: center;
								grid-gap: 1rem;
								gap: 1rem;
							}
						}

						.mobile-caixa-result {
							padding: 1rem 2rem;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							gap: 1rem;

							.mobile-numbers {
								display: flex;
								flex-direction: row;
								gap: 0.5rem;
								flex-wrap: wrap;

								color: $white;

								.mobile-number {
									width: 2rem;
									height: 2rem;
									padding: 0.4rem;
									border-radius: 50%;

									background: $black;

									text-align: center;
								}

								.mobile-complement {
									width: auto !important;

									margin: 0 0 0 5px !important;
									padding: 7px 15px !important;

									display: flex;
									flex-direction: column;

									align-items: center;
									justify-content: center;

									background-color: $black;
									color: $white;

									border-radius: 7px !important;
									text-align: center;

									font-size: 0.8rem;
								}
							}
						}

						li:first-child {
							span {
								display: flex;
								align-items: center;
								gap: 1rem;
							}
						}

						li {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;

							padding: 0.6rem;

							background: $white;
						}
					}

					&.mobile-secondary-list {
						margin-top: 0;
						li {
							display: flex;
							flex-direction: row;
							justify-content: space-between;

							padding: 1rem 2rem;

							background: $lightGray;
						}
					}
				}

				.mobile-receipt-button {
					display: flex;
					gap: 1rem;
					justify-content: center;
					align-items: center;

					width: 90%;

					background: $green;
					color: $white;
				}

				.mobile-description {
					padding: 1rem 2rem;
					margin: 1rem;
				}

				.mobile-games {
					display: flex;
					flex-direction: column;
					justify-content: left;
					gap: 0.5rem;

					padding: 1rem 2rem;

					.mobile-game {
						display: flex;
						flex-direction: row;
						justify-content: left;
						flex-wrap: wrap;

						span {
							margin-left: 1rem;
						}
						span:first-child {
							font-weight: bold;
						}
					}
				}

				#mobile-see-more {
					font-size: 1rem;
					color: $green;
					text-decoration: underline;
					margin-left: 3rem;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.mobile-second-tab {
				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: flex-start;
						gap: 1rem;

						padding: 1rem 3rem;
						margin-bottom: 1rem;
						border-bottom: 1px solid $lightGray;

						.mobile-prize {
							display: flex;
							gap: 2rem;
						}

						.mobile-award {
							width: -webkit-fill-available;
							display: flex;
							flex-direction: column;
							gap: 0.5rem;
							font-size: 0.98rem;

							.mobile-award-li {
								display: flex;
								flex-direction: row;
								gap: 0.5rem;
							}
						}

						.mobile-game-numbers {
							display: flex;
							flex-direction: column;
							gap: 1rem;

							width: 100%;
							position: relative;
							flex-wrap: wrap;

							.mobile-first-draw,
							.mobile-second-draw {
								display: flex;
								flex-direction: column;
								justify-content: flex-end;
								gap: 2rem;

								font-weight: 500;

								.mobile-info {
									display: flex;
									justify-content: space-between;
									align-items: center;

									width: 8rem;
								}

								.mobile-numbers {
									display: flex;
									flex-direction: row;
									gap: 0.5rem;
									flex-wrap: wrap;

									.mobile-number {
										width: 2rem;
										height: 2rem;
										padding: 0.4rem;
										border-radius: 50%;

										background: $lightGray;
										color: $black;

										text-align: center;

										&.mobile-active {
											background: $black;
											color: $white;
										}
									}

									.mobile-complement {
										width: auto !important;

										margin: 0 0 0 5px !important;
										padding: 7px 15px !important;

										display: flex;
										flex-direction: column;

										align-items: center;
										justify-content: center;

										background-color: $lightGray;
										color: $darkGray;

										border-radius: 7px !important;
										text-align: center;

										font-size: 0.8rem;

										@include transition;
										cursor: default;

										text-transform: uppercase;

										&.mobile-active {
											background: $black;
											color: $white;
										}
									}
								}
							}
						}
					}
				}
			}

			.mobile-third-tab {
				.mobile-active-square {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $lightGreen;
				}
				.mobile-active-square-gray {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $silver;
				}

				.mobile-inative-square {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $silver;
				}

				.mobile-inative-square-red {
					width: 1.56rem;
					height: 1.56rem;
					border-radius: 50%;
					background: $red;
				}

				.mobile-list {
					display: flex;
					flex-direction: column;
					gap: 0.1rem;

					padding: 0;

					list-style: none;
					width: 100%;

					margin-top: 3rem;

					li {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;

						padding: 0.6rem;

						background: $white;

						span:first-child {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							gap: 1rem;
						}
					}
				}

				.mobile-historic-title {
					padding: 1rem;
				}

				.mobile-historic-timeline {
					width: 100%;

					.mobile-historic-timeline-date {
						flex: none;
						width: 55%;
					}
				}
			}

			.mobile-search-bar {
				width: 100%;
				margin: 15px 0;

				padding: 0.5rem 3rem;
				border-radius: 5px;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 0.5rem;

				background-color: $lightGray;

				strong {
					font-weight: 700;
					font-size: 0.9rem;
				}
				.mobile-form-group {
					margin: 0;
					position: relative;

					button {
						background-color: transparent;
						position: absolute;

						border: 0;
						color: $darkGray;

						cursor: pointer;
						font-size: 0.9rem;

						&.mobile-submit {
							top: 5px;
							right: 5px;
						}
						&.mobile-clear {
							top: 8px;
							right: 30px;
							color: $gray;
							.icon {
								font-size: 1.2rem;
							}
						}
					}
				}
			}
			.mobile-games {
				width: 100%;
				position: relative;

				&.mobile-close-games {
					height: 5rem;
					overflow-y: hidden;
				}

				&.mobile-open-games {
					overflow-y: scroll;
				}

				> div {
					display: flex;

					flex-direction: column;
					align-items: center;
					justify-content: center;

					.mobile-line {
						display: flex;
						justify-content: space-between;

						width: 100%;
						padding: 10px;

						border-bottom: 1px solid $lightGray;
						font-size: 1rem;
						color: $darkGray;

						.mobile-id {
							width: fit-content;
							display: block;
						}
						.mobile-numbers {
							display: flex;
							align-items: center;

							span {
								width: 35px;
								height: 35px;

								margin: 0 5px;

								display: flex;
								flex-direction: column;

								align-items: center;
								justify-content: center;

								background-color: $lightGray;
								color: $darkGray;

								border-radius: 100%;
								text-align: center;

								font-size: 0.8rem;
								padding: 5px;

								@include transition;
								cursor: default;

								&.active {
									background-color: $blue;
									color: $white;
								}
								&:hover {
									background-color: $gray;
									color: $white;
								}
							}
						}

						.mobile-links {
							display: block;
						}
					}
				}
			}
			.mobile-pagination {
				width: 100%;
				margin: 30px 0 15px;

				display: flex;
				flex-direction: row;

				align-items: center;
				justify-content: center;
			}
			.mobile-loading {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;

				background-color: rgba(241, 239, 239, 0.6);

				z-index: 10;
				margin: 0;
				padding: 50px;

				display: flex;
				flex-direction: column;

				align-items: center;
				justify-content: center;

				svg {
					color: $green;
					font-size: 5rem;
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.mobile-jackpot-modal {
		.mobile-modal-box {
			width: 70vw;
			.mobile-modal-body {
				padding: 30px;

				.mobile-search-bar {
					align-items: center;
					flex-direction: row;
					.mobile-form-group {
						width: 40%;
					}
				}
				.mobile-games {
					.mobile-line {
						align-items: center;
						flex-wrap: wrap;
						.mobile-numbers {
							flex-flow: row wrap;
						}
						.mobile-links {
							width: 40%;
							font-size: 0.8rem;
							text-align: right;
						}
					}
				}
			}
		}
	}
}
