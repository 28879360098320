@import '../../assets/sass/app.scss';

    main#about {

        section#content-about {
            padding: 60px 0;

            .row {
                display:   flex;
                flex-flow: row wrap;

                align-items:     flex-start;
                justify-content: space-between;

                overflow: unset;
            
                h1 {
                    width:  100%;
                    margin: 0 auto 30px;
    
                    font-weight:  900;
                    font-size: 2.4rem;
                    
                    color:     $black;
                    line-height:  1.2;
    
                    text-align: center;
                }
                figure {
                    display:     block;
                    overflow:   hidden;
    
                    position: sticky;
                    top:        80px;
    
                    img {
                        width:  70%;
                        height: 70%;
    
                        object-fit:     contain;
                        object-position: center;
    
                        display: block;
                        margin:   auto;
                    }
                }
                .text {
                    p {
                        font-weight: 400;
                        font-size: .9rem;
                        
                        color: $darkGray;
                        line-height: 1.4;
                    }
                }
            }
        }

    }

    @include media-breakpoint-down(sm) {
        main#about {
            section#content-about {
                figure { width: 100%; }
                .text  { width: 100%; padding: 0 15px; } 
            }
        }
    }

    @include media-breakpoint-up(lg) {
        main#about {
            section#content-about {
                figure { width: 40%; }
                .text  { width: 60%; }
            }
        }
    }