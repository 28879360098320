@import '../../assets/sass/app.scss';

    section#types {
        width: 100%;
        background: linear-gradient($white 88%, $silver 12%);
        position: relative;
        
        .row {
            width:   100%;
            padding: 0 0 15px;

            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: flex-start;

            overflow-x:  auto;
            flex-wrap: nowrap;

            &::-webkit-scrollbar { display: none; }
            -ms-overflow-style: none;
            scrollbar-width:    none;

            .item {
                text-align: center;
                font-weight:   400;
                
                font-size:   .9rem;
                text-align: center;

                cursor: pointer;
                display:   flex;

                flex-flow:       column;
                align-items:     center;
                justify-content: center;
                
                @include transition;
                position:  relative;

                background-color: $white;
                
                span {
                    display: block;
                    width:    100%;
                    margin:  5px 0;

                    text-align: center;
                    font-weight:   400;
                    
                    font-size:   .9rem;
                    text-align: center;
                }
                strong {
                    font-weight:   700;
                    font-size:  1.6rem;

                    display:     block;
                    width: fit-content;
                    margin:   5px auto;

                }

                &.all {
                    font-weight: 700;
                    font-size:  1rem;
                }
                &.active {
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.161);
                    box-shadow:  0px 3px 3px rgba(0, 0, 0, 0.161);
                    
                    &::after {
                        content: "";

                        width:  0;
                        height: 0;

                        border-style: solid;
                        border-width: 11px 13.5px 0 13.5px;
                        border-color: $white transparent transparent transparent;

                        position:       absolute;
                        bottom: -11px; left: 50%;

                        transform: translateX(-50%);
                    }
                }

                &:nth-child(1)        { color: $black;      &.active { background-color: $yellow;     color: $white; &::after { border-color: $yellow transparent transparent transparent;     }}}
                &.mega-sena-da-virada { color: $megaSena;   &.active { background-color: $megaSena;   color: $white; &::after { border-color: $megaSena transparent transparent transparent;   }}}
                &.mega-sena           { color: $megaSena;   &.active { background-color: $megaSena;   color: $white; &::after { border-color: $megaSena transparent transparent transparent;   }}}
                &.timemania           { color: $timeMania;  &.active { background-color: $timeMania;  color: $white; &::after { border-color: $timeMania transparent transparent transparent;  }}}
                &.quina               { color: $quina;      &.active { background-color: $quina;      color: $white; &::after { border-color: $quina transparent transparent transparent;      }}}
                &.lotofacil           { color: $lotoFacil;  &.active { background-color: $lotoFacil;  color: $white; &::after { border-color: $lotoFacil transparent transparent transparent;  }}}
                &.lotomania           { color: $lotoMania;  &.active { background-color: $lotoMania;  color: $white; &::after { border-color: $lotoMania transparent transparent transparent;  }}}
                &.loteca              { color: $loteca;     &.active { background-color: $loteca;     color: $white; &::after { border-color: $loteca transparent transparent transparent;     }}}
                &.dupla-sena          { color: $duplaSena;  &.active { background-color: $duplaSena;  color: $white; &::after { border-color: $duplaSena transparent transparent transparent;  }}}
                &.dia-de-sorte        { color: $diaDeSorte; &.active { background-color: $diaDeSorte; color: $white; &::after { border-color: $diaDeSorte transparent transparent transparent; }}}
                &.super-sete          { color: $superSete; &.active { background-color: $superSete; color: $white; &::after { border-color: $superSete transparent transparent transparent; }}}
                &.mais-milionaria     { color: $maisMilionaria; &.active { background-color: $maisMilionaria; color: $white; &::after { border-color: $maisMilionaria transparent transparent transparent; }}}
            }
        }
        .container {
            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: space-between;
        }
        .arrows {
            font-weight: 700;
            font-size:  2rem;
            
            color:   $lightGray;
            cursor:     pointer;
            @include transition;

            &:hover {
                color: $gray;
            }
        }
        .scrollLeft {
            @extend .arrows;
            margin:  0 15px;

            &:hover { transform: translateX(-5px); }
        }
        .scrollRight {
            @extend .arrows;
            margin:  0 15px;

            &:hover { transform: translateX(5px); }
        }
        .loading {
            width:  100%;
            height: 100%;

            position: absolute;
            top:  0;  left:  0;

            background-color: rgba(255, 255, 255, .8);

            display:        flex;
            flex-direction: column;

            align-items:     center;
            justify-content: center;

            svg {
                color:   $green;
                font-size: 5rem;
            }
        }
    }

    section#filter {
        width: 100vw;

        background-color: $silver;
        padding:           15px 0;

        .row {
            position:  relative;
            padding:   0 15px;

            display:   flex;
            flex-flow: row wrap;

            align-items:      center;
            justify-content:  space-between;

            .filter-params {
                display: flex;
                
                flex-flow: row wrap;
                align-items: center;

                .filter-button {
                    background-color: $blue;
                    border-radius:     25px;
        
                    padding: 3px 15px;
                    font-weight:  300;
        
                    color:          $white;
                    text-transform: uppercase;
        
                    border:  0;
                    display: flex;
        
                    flex-direction: row;
                    align-items: center;
        
                    font-size: .7rem;
                    cursor:  pointer;
        
                    border: 1px solid $blue;
                    margin: 0 5px;
        
                    @include transition;
        
                    &:hover {
                        background-color: $white;
                        color:             $blue;
                    }
                }
                .params {
                    display: inline-block;
                    min-width:      70px;
        
                    background-color: $lightBlue;
                    border-radius:          25px;
                    
                    font-weight: 400;
                    font-size: .7rem;
                    
                    color:       $blue;
                    display:     flex;
                    align-items: center;
                    padding: 5px 5px 5px 10px;
                    
                    justify-content: space-between;
                    position:        relative;
        
                    svg { font-size: .8rem; margin-left: 5px; cursor: pointer; }
                }
                .num-rows {
                    font-weight: 300;
                    font-size: .9rem;
                    color:    $black;

                    strong { font-weight: 700; }
                }
            }
            #filter-box {
                position: absolute;
                top: 50px; left: 50%;

                transform: translateX(-50%);
                z-index:   4;
    
                visibility:       hidden;
                background-color: $white;
    
                opacity: 0;
                @include transition;
    
                display:        flex;
                flex-direction: column;
    
                align-items:     center;
                justify-content: center;
    
                box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    
                &.active {
                    opacity:    1;
                    visibility: visible;
                }
    
                .close-filter {
                    position:    absolute;
                    top: 10px; right: 10px;
    
                    color:  $gray;
                    cursor: pointer;
    
                    @include transition;
    
                    &:hover {
                        color: $black;
                    }
                }
                .line {
                    margin: 10px auto;
                    
                    padding: 15px 0;
                    border-bottom: 1px solid $lightGray;
    
                    &:last-of-type() { border: 0; }
                    label {
                        width: 100%;
    
                        font-weight: 700;
                        font-size: .9rem;
                    }
                    > button {
                        margin: 15px auto;
                        padding: 5px 10px;
                    }
                    .slider-price { 
                        width:  90%;
                        margin: 0 auto;
    
                        color:   $blue;
                        display: block; 
    
                        .MuiSlider-valueLabel {
                            transform: scale(1) translateY(55px);
                            // &:hover { transform: scale(1) translateY(-5px); }
    
                            > span {
                                width:  80px;
                                height: 30px;
    
                                border-radius:      5px;
                                transform: rotate(0deg);
                                
                                > span {
                                    transform: rotate(0deg);
                                }
                                
                                &::after {
                                    content: "";
            
                                    width:  0;
                                    height: 0;
            
                                    border-style: solid;
                                    border-width: 0 10px 10px 10px;
                                    border-color: transparent transparent $blue transparent;
            
                                    position:   absolute;
                                    top: -9px; left: 5px;
                                }
                            }
                        }
                    }
                    .days {
                        width:   100%;
                        display: flex;
    
                        align-items:     center;
                        justify-content: flex-start;
    
                        .day {
                            background-color: $lightBlue;
    
                            padding: 5px 10px;
                            font-weight:  400;
                            font-size:  .8rem;
    
                            color:        $blue;
                            border-radius: 25px;
    
                            text-align: center;
                            cursor:    pointer;
    
                            @include transition;
    
                            &:hover {
                                background-color: $blue;
                                color:       $lightGray;
                            }
                            &.active {
                                background-color: $blue;
                                color:       $lightGray;
                            }
                            > input { display: none; }
                        }
                    }
                }
            }
        }
    }
    
    @include media-breakpoint-down(sm) {

        section#types {
            .row { 
                .item {
                    flex: 1 0 50%;
                    height:  80px;
                    padding: 15px;
                }
            }
            .arrows {
                display: none;
            }
        }

        section#filter {
            .row {
                .filter-params {

                    .params { 
                        margin: 5px 0;
                    }
                }
                .num-rows {
                    display: block;
                    margin:  10px 5px;
                }
            }
            #filter-box {
                width:   100vw;
                height:  fit-content;
                padding: 25px 15px; 

                .line { 
                    width: 80%;
                    .days { 
                        flex-flow: row wrap; 
                        
                        .day { width: 80px; margin: 5px; }
                    }
                }
                button { width: 60%; }
            }
        }
    }

    @include media-breakpoint-up(lg) {

        section#types {
            .row {
                .item {
                    flex: 1 0 20%;
                    height: 110px;
                    padding: 20px;
                }
            }
        }

        section#filter {
            .row {
                .filter-params {
                    width: 40%;
                    .params { 
                        margin: 0 5px;
                    }
                }
            }
            #filter-box {
                width:        100%;
                min-height:  400px;
                padding:      25px;

                .line { width: 70%; .days { .day { width: 90px; margin: 0 10px; }}}
                button { width: 30%; }
            }
        }
    }