@import '../../assets/sass/app.scss';

    .show-balance, .show-balance-mobile {
        width: fit-content;
        min-width:   120px;
        max-width:   200px;
        margin:  0 5px 0 0;

        display:      flex;

        flex-direction:  row;
        align-items:     center;
        justify-content: space-around;
        
        border-radius:     50px;
        border: 1px solid $blue;
        
        padding:    8px 15px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.161);

        @include transition;

        background-color: $blue;
        color:           $white;

        font-size:   .8rem;
        position: relative;

        z-index: 2;
        text-transform: capitalize;

        svg { font-size: 1rem; margin: 0 5px 0 0; }
    }
    
    #user-menu {
        position: relative;

        .menu {
            width: fit-content;
            min-width:   120px;
            max-width:   200px;
            display:      flex;

            flex-direction:  row;
            align-items:     center;
            justify-content: space-around;
            
            border-radius:      50px;
            border: 1px solid $green;
            
            padding:    5px 15px;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.161);
    
            @include transition;
            cursor:     pointer;
    
            background-color: $white;
            color:            $green;

            font-size:   .9rem;
            position: relative;

            z-index: 2;
            text-transform: capitalize;
            
            &:hover {
                background-color: $green;
                color:            $white;
            }
        }
        .submenu {
            width:  200px;
            display: flex;

            z-index: 1;
            
            padding:    10px 0 10px;
            visibility: hidden;

            flex-direction:  column;
            align-items: flex-start;
            justify-content: center;

            position: absolute;
            top: 30px;
            left: 0px;

            list-style:         none;
            background-color: $white;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.161);
            
            border-radius:  5px;
            @include transition;

            &.active {
                visibility: visible;
            }

            li {
                width:     100%;
                padding:   10px;

                font-size: .9rem;
                cursor:  pointer;
                
                border-bottom: 1px solid $lightGray;
                @include transition;

                &:hover {
                    background-color: $silver;
                }
                &:last-child { border: 0; }

                &.user-wallet {
                    background: $blue;
                    &:hover {
                        background: #0379ac;
                    }
                    a {
                        display: flex;
                        flex-direction: column;
                    }
                    a, a span{
                        color: $white !important;
                    }
                    a .title {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    #user-menu-mobile {
        width:  100%;
        height: 250px;

        background-color: rgba(255, 255, 255, .2);
        padding: 20px;
        padding-top: 80px;

        flex-direction: column;
        a { 
            font-weight:  900;
            font-size: 1.2rem;

            color:  $white; 
            margin: 10px 0;

            display: flex;

            flex-direction: row;
            align-items: center;

            svg { margin-right: 5px; }
        }
    }
    #account-mobile {
        @extend #user-menu-mobile;
    }

    @include media-breakpoint-down(sm) {
        #user-menu        { display: none; }
        #user-menu-mobile { display: flex; }
        #account          { display: none; }
        #account-mobile   { display: flex; }
        #user-balance     { margin-top: 25px !important; }
        #account .show-balance { display: none; }
    }

    @include media-breakpoint-up(lg) {
        #user-menu        { display: flex; }
        #user-menu-mobile { display: none; }
        #account          { display: flex; }
        #account-mobile   { display: none; }
        #user-balance     { margin-top: 0 !important; }
        #user-balance     { display: flex; }
    }