@import '../../assets/sass/app.scss';

    section#section-work {
        width:   100%;
        padding: 80px 0;

        background-color: $green;

        .row {
            align-items:     flex-start;
            justify-content: space-around;


            h1 {
                width:        100%;
                text-align: center;

                font-weight:   700;
                font-size:  1.8rem;
                
                color:  $white;
                margin: 0px 0px 50px;
            }
            article {
                flex:   1 0 25%;
                padding: 0 15px;

                figure {
                    width:  80px;
                    height: auto;

                    overflow:  hidden;
                    margin: 10px auto;

                    border-radius: 100%;

                    img {
                        width:  100%;
                        height: 100%;

                        display: block;

                        object-fit:       cover;
                        object-position: center;
                    }
                }
                h1 {
                    width:         90%;
                    text-align: center;

                    font-weight: 700;
                    font-size:  1rem;
                    
                    color:  $white;
                    margin: 10px 0;
                }
                p {
                    font-weight: 300;
                    font-size: .9rem;
                    
                    color:      $white;
                    text-align: center;
                    line-height:   1.4;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .row {
                flex-flow:   row;
                overflow-x: auto;

                align-items:     center;
                justify-content: space-between;

                article {
                    flex: 1 0 300px;
                    figure { width:  50%; }
                    .text  { width: 100%; padding: 0 15px; }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .row {
                align-items:     center;
                flex-flow:       row wrap;
                justify-content: space-between;

                figure { width: 30%; }
                .text  { width: 70%; }
            }
        }
    }