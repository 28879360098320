@import '../../assets/sass/app.scss';

    main#contact {
        width:  100%;
        height: auto;
        
        #formulary {
            h1 {
                font-weight:   700;
                text-align: center;
            }
            label { font-weight: 700; font-size: .9rem; margin: 0; }
            textarea { width: 100%; height: 150px; }
            button   { margin: 20px auto; }
        }
    }

    @include media-breakpoint-down(sm) {
        main#contact {
            #formulary {
                width:  80%;
                margin: 50px auto;
                h1 { font-size: 1.4rem; }
                button { width: 70%; }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        main#contact {
            #formulary {
                width:  50%;
                margin: 50px auto;
                h1 { font-size: 2.2rem; }
                button { width: 30%; }
            }
        }
    }