@import '../../assets/sass/app.scss';

    .body{
        width: 20rem;
        height: 15rem;
        //justify-content: center;
        //align-items: center;
        text-align: center;
	      position: relative;
	      display: flex;
	      flex-direction: column;

        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;

        @for $i from 2 through 10 {
            $percent: $i*1%;
            // &.mega-sena-da-virada-#{$i} { background-color: lighten($megaSenaDaVirada,$percent);color: $white;}
            &.mega-sena-#{$i}           { background-color: lighten($megaSena,$percent);color: $white;}
            &.timemania-#{$i}           { background-color: lighten($timeMania,$percent);color: $white;}
            &.quina-#{$i}               { background-color: lighten($quina,$percent);color: $white;}
            &.lotofacil-#{$i}           { background-color: lighten($lotoFacil,$percent);color: $white;}
            &.lotomania-#{$i}           { background-color: lighten($lotoMania,$percent);color: $white;}
            &.loteca-#{$i}              { background-color: lighten($loteca,$percent);color: $white;}
            &.dupla-sena-#{$i}          { background-color: lighten($duplaSena,$percent);color: $white;}
            &.dia-de-sorte-#{$i}        { background-color: lighten($diaDeSorte,$percent);color: $white;}
            &.super-sete-#{$i}          { background-color: lighten($superSete,$percent);color: $white;}
            &.mais-milionaria-#{$i}     { background-color: lighten($maisMilionaria,$percent);color: $white;}
        }

        &.mega-sena-da-virada   { background-image: $megaSenaDaVirada;   color: $white; }
        &.mega-sena-1           { background-color: $megaSena;   color: $white; }
        &.timemania-1           { background-color: $timeMania;  color: $white; }
        &.quina-1               { background-color: $quina;      color: $white; }
        &.lotofacil-1           { background-color: $lotoFacil;  color: $white; }
        &.lotomania-1           { background-color: $lotoMania;  color: $white; }
        &.loteca-1              { background-color: $loteca;     color: $white; }
        &.dupla-sena-1          { background-color: $duplaSena;  color: $white; }
        &.dia-de-sorte-1        { background-color: $diaDeSorte; color: $white; }
        &.super-sete-1          { background-color: $superSete; color: $white; }
        &.mais-milionaria-1     { background-color: $maisMilionaria; color: $white; }

        &.all                 {
            background-color:#FFBA05;
            color: $white;
        }

        &.all-concourses{
            justify-content: center;
            align-items: center;
            display: flex;
        }

        .header{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin-top: 2rem;
            margin-left: 2rem;
            text-align: center;

            width: 20rem;

            &.all-concourses{
                margin-top: 0%;
                margin-left: 0%;

            }

            .title{
                display: flex;
                flex-direction: row;
                text-align: left;
                width: 100%;
                gap: 1rem;

                font-size: 1.3rem;
                font-weight: bold;


                .text{
                    width: 70%;

                    &.all-concourses{
                        width: 100%;
                        text-align: center;
                    }

                    .subtitle{
                        font-size: 0.87rem;
                        font-weight: 100;
                    }

                }

            }
        }

        .content{
            display: flex;
            flex-direction: column;

            width: auto;
            justify-self: center;
            justify-content: center;
            align-items: center;

            .prize{
                color: $white;
                font-size: 1.5rem;
                font-weight: bold;

                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
            }

            .concourse-button {
                -webkit-transition: all .5s;
                -o-transition: all .5s;
                transition: all .5s;
                -webkit-user-select: none;
                   -moz-user-select: none;
                    -ms-user-select: none;
                        user-select: none;
                padding: 10px 25px;
                text-align: center;
                align-items: center;
                border: none;
                border-radius: 50px;
                margin-top: 1rem;
                color: $white;

                a{
                    color: $black;
                }

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        a{
            width: 100%;
            color: white;
        }
        .body{
            width: 100%;
            justify-content: center;

            .header{
                justify-content: center;

                .title{
                    justify-content: right ;
                }
            }

            &.all{
                height: 8rem;
            }

            &.all-concourses{
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }

    @include media-breakpoint-up(lg) {

    }
