@import '../../assets/sass/app.scss';

    section#section-lets-play {
        width:   100%;

        background-color: $purple;
        background-image: url(../../assets/images/background-letsplay.png);

        background-repeat:  no-repeat;

        .row {
            align-items:     flex-start;
            justify-content: space-around;

            h1 {
                width:        100%;
                text-align: center;

                font-weight:   900;
                font-size:  2.8rem;
                
                color:  $white;
                margin: 15px 0;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 50px 0;
            
            background-size:   cover;
            background-position: 60%;

            .content { 
                width:     100%;
                padding: 0 15px;

                button { margin: 60px auto 0; }
            }
        }

        @include media-breakpoint-up(lg) {
            padding: 100px 0;

            background-size:      cover;
            background-position: center;

            .content { margin-left: 30%; }
        }
    }