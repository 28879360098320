@import '../../assets/sass/app.scss';

    .jackpot-box {
        border-radius: 5px;
        padding:       0px;

        display:          flex;
        flex-direction: column;

        align-items:      center;
        justify-content:  space-between;


        overflow:         hidden;
        background-color: $white;

        @include transition;

        &:hover {
            transform:  scale(1.02);
            box-shadow: 1px 4px 9px 0px rgba(42, 43, 65, 0.349);
        }
        .header {
            width:             100%;
            background-color: $gray;

            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: space-between;

            padding: 10px 15px;

            .title {
                font-weight: 700;
                font-size: .8rem;
                color:    $white;

                width: 100%;

                display:     flex;
                align-items: center;

                img { margin: 0 10px 0 0; }

                .title-price{
                    margin-left: auto;
                }

                .title-cota {
                    font-weight: 200;
                }

            }
            .ref {
                font-weight: 300;
                font-size: .8rem;
                color:    $white;
            }
            &.mega-sena-da-virada { background-image: $megaSenaDaVirada;   color: $white; }
            &.mega-sena           { background-color: $megaSena;   color: $white; }
            &.timemania           { background-color: $timeMania;  color: $white; }
            &.quina               { background-color: $quina;      color: $white; }
            &.lotofacil           { background-color: $lotoFacil;  color: $white; }
            &.lotomania           { background-color: $lotoMania;  color: $white; }
            &.loteca              { background-color: $loteca;     color: $white; }
            &.dupla-sena          { background-color: $duplaSena;  color: $white; }
            &.dia-de-sorte        { background-color: $diaDeSorte; color: $white; }
            &.super-sete          { background-color: $superSete; color: $white; }
            &.mais-milionaria          { background-color: $maisMilionaria; color: $white; }
        }
        .modal-body {
            width:   100%;
            padding: 15px 15px 0 15px;

            ul {
                list-style: none;
                padding:    0;

                li {
                    width: 80%;

                    padding: 3px 10px;
                    font-weight:  400;

                    font-size: .8rem;
                    color:     $gray;

                    display:         flex;
                    align-items:     center;

                    img { margin: 0 10px 0 0; }

                    &.ref   { font-size: .75rem; padding: 0 0 0 10px; line-height: 1.4; }
                    &.space { padding: 5px 0; }
                }
            }
            hr {
                width:  90%;
                margin: 5px auto;

                border: 0;
                border-top: 1px solid $silver;
            }
            .show {
                text-decoration: underline;
                cursor:            pointer;
            }
            .value {
                width:    100%;
                display: block;

                padding:   15px 0;
                font-weight:  900;

                font-size: 1.2rem;
                color:     $black;

                text-align: center;
            }
            .quantity {
                width:    100;
                position: relative;

                input {
                    width:    100%;
                    display: block;

                    padding:   7px 10px;
                    border-radius: 25px;

                    border: 1px solid $gray;
                    text-align:      center;

                    font-size: 1rem;
                    color:    $gray;

                    /** Remove os manipuladores **/
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    &[type=number] {
                        -moz-appearance: textfield;
                    }
                }
                > span {
                    width:  50px;
                    height:  89%;

                    padding:   5px 10px;
                    border-radius: 15px;

                    color:       $white;
                    position:  absolute;

                    opacity: .8;
                    z-index:  2;

                    @include transition;
                    cursor: pointer;

                    text-align: center;
                    font-size:  1.4rem;

                    -webkit-touch-callout: none;
                    -webkit-user-select:   none;
                    -khtml-user-select:    none;
                    -moz-user-select:      none;
                    -ms-user-select:       none;
                    user-select:           none;
                }
                .sub { top: 2px; left:  2px; background-color: $gray; &:hover { opacity: 1; }}
                .sum { top: 2px; right: 2px; background-color: $blue; &:hover { opacity: 1; }}
            }
            .action {
                width:    100%;
                display: block;

                text-align: center;
                margin:     15px 0 40px;

                position: relative;

                small {
                    display: block;
                    width:    100%;
                    opacity:     0;

                    padding: 10px 0;
                    transition: all .8s ease-in-out;

                    font-weight: 700;
                    font-size: .8rem;
                    color:     $blue;

                    text-align:    center;
                    text-decoration: none;

                    position:     absolute;
                    bottom: -35px; left: 0;

                    &.show {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .jackpot-box { width: 90%; margin: 15px auto; }
    }

    @include media-breakpoint-up(lg) {
        .jackpot-box { width: 24%; margin: 10px 5px; }
    }
