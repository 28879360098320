@import '../../assets/sass/app.scss';

    section#section-reviews {
        width:   100%;
        padding: 50px 0;

        .row {
            align-items:     flex-start;
            justify-content: space-around;
            flex-direction:  column;
            position:        relative;

            h1 {
                width:        100%;
                text-align: center;

                font-weight:   700;
                font-size:  1.8rem;
                
                color:  $black;
            }
            .swiper-container {
                .swiper-slide {
                    padding: 20px 0;

                    .box {
                        
                        display: flex;
                        flex-direction: column;
                        
                        align-items:     center;
                        justify-content: center;
                        
                        border-radius: 10px;
                        margin:      0 auto;
                        
                        background-color: $lightGray;
                        box-shadow: 0px 3px 6px #00000029;
                        
                        p {
                            font-weight: 400;
                            font-size: .9rem;
                            
                            color:    $black;
                            line-height: 1.4;

                            padding: 5px 0;

                            &::before { content: "\"" }
                            &::after  { content: "\"" }
                        }
                        figure {
                            width:  100px;
                            height: 100px;

                            margin: 15px auto;
                            overflow:  hidden;

                            border-radius: 100%;

                            img {
                                width:  100%;
                                height: 100%;

                                object-fit:       cover;
                                object-position: center;

                                display: block;
                            }
                        }
                        span {
                            font-size: .9rem;
                            color:    $black;
                        }
                    }
                }
            }
            .swiper-pagination-ext {
                width:     fit-content;
                transform: translateX(-50%);

                position:   absolute;
                bottom: 0; left: 50%;

                .swiper-pagination-bullet {
                    width:  8px;
                    height: 8px;

                    margin: 0 2px;
                    background-color: $gray;

                    &.swiper-pagination-bullet-active {
                        width:  10px;
                        height: 10px;

                        background-color: $yellow;
                    }
                }
            }
        }

    }

    @include media-breakpoint-down(sm) {
        section#section-reviews {
            .row {
                .swiper-container {
                    width:  100%;
                    margin: 30px auto;
                    
                    .swiper-slide {
                        padding: 20px 0;
                        .box {
                            width:   80%;
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        section#section-reviews {
            .row {
                .swiper-container {
                    width:  80%;
                    margin: 30px auto;

                    .swiper-slide {
                        padding: 20px 0;
                        .box {
                            width:   70%;
                            padding: 20px 40px;
                        }
                    }
                }
            }
        }
    }