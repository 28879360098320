@import '../../assets/sass/app.scss';

    .buy-credits {
        width:   100%;
        padding: 20px 0 40px;

        display:           flex;
        align-items:     center;
        justify-content: center;

        -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;


        span {
            font-weight:  700;
            font-size: 1.8rem;
        }
        button {
            margin:  0 15px;
            padding: 5px 19px;
            
            border:     2px solid $green;
            font-weight: 400;
            font-size: .9rem;
        }
    }
    .cards-title{
        color: $megaSena;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        line-height: 2rem;

        width: 100%;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .jackpot-totals{
        width: 100%;
        text-align: center;
        margin: 0 0 3rem 0;
    }

    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0%;
        margin-bottom: 2rem;
    }

    @include media-breakpoint-down(sm) {
        .buy-credits {
            flex-direction: column;
            span { display: block; width: 100%; margin: 15px 0; text-align: center; line-height: 1.2; }
        }
        .cards-title{
            padding: 3rem 1rem 3rem 1rem;
            font-size: 28px;
        }
        .cards {
            justify-content: unset;
        }
        .list { 
            .row {
                width:  100%;
                margin: 0;

                flex-direction: column;
                flex-wrap:      nowrap;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .buy-credits {
            flex-direction: row;
        }
        .list { 
            .row {
                flex-flow: row wrap;
            }
        }
    
    }