@import '../../assets/sass/app.scss';

    section#jackpots {
        background-color: $silver;
        .buy-credits {
            width:   100%;
            padding: 20px 0 40px;

            display:           flex;
            align-items:     center;
            justify-content: center;

            span {
                font-weight:  700;
                font-size: 1.8rem;
            }
            button {
                margin:  0 15px;
                padding: 5px 19px;
                
                border:     2px solid $green;
                font-weight: 400;
                font-size: .9rem;
            }
        }
        .list {
            width:   100%;
            padding: 0 0 30px;

            background-color: $silver;

            .container {
                position: relative;
            }
            .row {
                display:         flex;
                align-items:     flex-start;
                justify-content: flex-start;
            }
            .pagination {
                display: block;
                width:   fit-content;
                margin:  20px auto;
            }
            .loading {
                width:  100%;
                height: 100%;
                
                position: absolute;
                top:  0;  left:  0;
                
                background-color: rgb(241, 239, 239);
                
                z-index:   10;
                margin:     0;
                padding: 50px;

                display:        flex;
                flex-direction: column;

                align-items:     center;
                justify-content: center;

                svg {
                    color:   $green;
                    font-size: 5rem;
                }
            }
            .empty {
                font-weight: 300;
                font-size:  1rem;
                
                color:   $darkGray;
                text-align: center;

                width:  100%;
                margin: 30px 0;

                display: block;
            }
        }

    }

    @include media-breakpoint-down(sm) {
        section#jackpots {
            .buy-credits {
                flex-direction: column;
                span { display: block; width: 100%; margin: 15px 0; text-align: center; line-height: 1.2; }
            }
            .list { 
                .row {
                    width:  100%;
                    margin: 0;

                    flex-direction: column;
                    flex-wrap:      nowrap;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        section#jackpots {
            .buy-credits {
                flex-direction: row;
            }
            .list { 
                .row {
                    flex-flow: row wrap;
                }
            }
        }
    }