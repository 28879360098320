@import '../../assets/sass/app.scss';

    .button {
        display: block;
        padding: 10px 25px;

        border-radius:           50px;
        border: 1px solid transparent;

        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.161);
        font-size: .9rem;
        
        @include transition;
        cursor:     pointer;
    }
    .green {
        @extend .button;

        background-color: $green;
        border-color:     $green;
        color:            $white;

        &:hover {
            background-color: $white;
            color:            $green;
        }
    }
    .blue {
        @extend .button;

        background-color: $blue;
        border-color:     $blue;
        color:            $white;

        &:hover {
            background-color: $white;
            color:            $blue;
        }
    }

    .blue-invert {
        @extend .button;

        background-color: $white;
        border-color:     $blue;
        color:            $blue;

        &:hover {
            background-color: $blue;
            color:            $white;
        }
    }
    .invert {
        @extend .button;
        background-color: $white;
        border-color:     $green;
        color:            $green;

        &:hover {
            background-color: $green;
            color:            $white;
        }
    }
    .lemon {
        @extend .button;
        background-color: $lightGreen;
        border-color:     $green;
        color:            $green;

        &:hover {
            background-color: $green;
            color:            $white;
        }
    }
    button:disabled,
    button[disabled] {
        @extend .button;
        background-color: $lightGray;
        border-color:     $gray;
        color:            $gray;

        cursor: not-allowed;

        &:hover {
            background-color: $lightGray;
            border-color:     $gray;
            color:            $gray;
        }
    }

    .with-progress {
        position: relative;
        width:        100%;
        height:       37px;

        .progress {
            width:  30px !important;
            height: 30px !important;

            position:  absolute;
            top: 50%; left: 50%;
            
            margin-top:  -15px;
            margin-left: -15px;

            color: $darkGray;
            background-color: transparent;
        }
    }