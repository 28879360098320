@import '../../assets/sass/app.scss';

    main#how-work {
        width:  100%;
        height: auto;
        
        section#content {
            h1 {
                width:  100%;
                margin: 30px auto;

                font-weight:  900;
                font-size: 2.4rem;
                
                color:     $black;
                line-height:  1.2;

                text-align: center;
            }
            article {
                width:    100%;
                margin: 15px 0;

                display:  flex;

                align-items:     flex-start;
                justify-content: space-between;

                figure {
                    display:        flex;
                    flex-direction: column;
                    overflow:       hidden;
        
                    img {
                        width:  70%;
                        height: 70%;
        
                        object-fit:     contain;
                        object-position: center;
        
                        display: block;
                        margin:   auto;
                    }
                }
                .content {
                    width: 70%;

                    h2 {
                        width:  100%;
                        margin: 15px auto;
            
                        font-weight:  900;
                        font-size: 1.4rem;
                        
                        color:     $black;
                        line-height:  1.2;
                    }
                    p {
                        font-weight: 400;
                        font-size: .9rem;
                        
                        color: $darkGray;
                        line-height: 1.4;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            section#content { 
                width:   100%;
                margin:  20px 0;
                padding: 0 15px;

                article { 
                    flex-direction: column; 
                    figure   { width:  80%; margin: auto; } 
                    .content { width: 100%; * { text-align: center; }}
                }
            }
        }

        @include media-breakpoint-up(lg) {
            section#content {
                width: 70%;
                margin: 20px auto 50px;

                article { 
                    flex-direction: row; 
                    figure { width: 30%; } .content { width: 70%; }
                    &:nth-of-type(odd)  { flex-direction:         row; .content { * { text-align:  left; }}}
                    &:nth-of-type(even) { flex-direction: row-reverse; .content { * { text-align: right; }}}
                }
            }
        }
    }