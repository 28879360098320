@import '../../assets/sass/app.scss';

    main#home {
        height: auto;

        #banner {
            .swiper-container {
                min-height: 700px;
            }
    
            .swiper-wrapper {
                height: 700px;
            }
    
            @media screen and (max-width: 1024px) {
                .swiper-container {
                    min-height: 500px;
                }
                .swiper-wrapper {
                    height: 500px !important;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .swiper-container {
                min-height: unset !important;
            }
            .swiper-wrapper {
                height: 100% !important;
            }
        }
        
        .swiper-container {
            .swiper-slide {
                figure {
                    width:  100%;
                    height: 100%;

                    overflow: hidden;
                    margin:   0;

                    img {
                        width:  100%;
                        height: 100%;

                        display: block;

                        object-fit:       cover;
                        object-position: center;
                    }
                }
            }
            .swiper-button-prev, .swiper-button-next {
                font-weight: 900;
                font-size: .8rem;
                
                color:    $white;
                width:      30px;
            }
            .swiper-button-prev { left:  50px; } 
            .swiper-button-next { right: 50px; }
            .swiper-pagination {
                width:     fit-content;
                transform: translateX(-50%);

                position:      absolute;
                bottom: 10px; left: 50%;

                .swiper-pagination-bullet {
                    width:  8px;
                    height: 8px;

                    margin: 0 2px;
                    background-color: $gray;

                    &.swiper-pagination-bullet-active {
                        width:  10px;
                        height: 10px;

                        background-color: $yellow;
                    }
                }
            }
        }  

        @include media-breakpoint-down(sm) {
            .swiper-container { height: 550px; }
            .swiper-button-prev, .swiper-button-next { display: none; }
            .swiper-pagination { display: flex; }
        }

        @include media-breakpoint-up(lg) {
            .swiper-button-prev, .swiper-button-next { display: block; }
            .swiper-pagination { display: none; }
        }
    }