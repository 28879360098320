@import '../../assets/sass/app.scss';

    main#buy-credits {
        position: relative;

        .feedback { margin: 15px auto !important; }
        #buttons {
            width:  100%;
            margin: auto;

            h1 {
                width:  100%;
                margin: 15px auto;
                
                font-weight:   700;
                font-size:    2rem;
                text-align: center;
            }
            p {
                width:  100%;
                margin: 35px auto 15px;

                font-weight:   700;
                font-size:    1rem;
                text-align: center;
            }
            .row {
                .list {
                    margin: 0 auto;

                    display:   flex;
                    flex-flow: row wrap;

                    align-items:     flex-start;
                    justify-content: flex-start;

                    .credit-button {

                        border-radius:      3px;
                        border: 1px solid $blue;

                        position:  relative;
                        @include transition;

                        display: flex;
                        flex-direction: column;

                        align-items:     center;
                        justify-content: center;
                        
                        
                        span { 
                            &:nth-of-type(1) {
                                position:  absolute;
                                top: 5px; left: 5px;
                            }
                            &:nth-of-type(2) {
                                font-weight:  700;
                                font-size: 1.1rem;

                                @include transition;
                            }
                        }
                        .checked { color: $blue; }
                        &:hover {
                            background-color: $blue;
                            * { color: $white; }
                        }
                    }
                }
                .division {
                    display: flex;
                    width:   100%;

                    font-weight: 300;
                    font-size:  1rem;

                    color:           $black;
                    align-items:     center;

                    margin:          15px 0;
                    justify-content: center;

                    span {
                        width:      5%;
                        text-align: center;
                    }
                    hr {
                        width: 30%;
                        
                        border:  0;
                        margin:  0;

                        border-bottom: 1px solid $lightGray;
                    }
                }
                .form-group {
                    button { margin: 0 auto; }
                }
                .totals {
                    display: flex;

                    align-items:     flex-end;
                    justify-content: flex-end;

                    small  { font-weight: 300; font-size:  .9rem; margin: 0 5px; }
                    strong { font-weight: 700; font-size: 1.2rem; }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        main#buy-credits {
            padding: 50px 0;

            .row {
                .list {
                    width:   100%;
                    padding: 0 15px;

                    .credit-button {
                        width:   46%;
                        height: 80px;
                        margin: 10px 5px;
                    }
                }
                .form-group {
                    width:  90%;
                    margin: 0 auto;
                }
                .totals {
                    width:   90%;
                    margin:  15px auto;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        main#buy-credits {
            padding: 120px  0 50px;

            .row {
                .list {
                    width: 60%;

                    .credit-button {
                        width:   30%;
                        height: 80px;
                        margin: 10px;
                    }
                }
                .form-group {
                    width:  60%;
                    margin: 0 auto;
                }
                .totals {
                    width:   60%;
                    margin:  15px auto;
                }
            }
        }
    }