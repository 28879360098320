@import '../../assets/sass/app.scss';

   #container-modal{
        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        align-self: center;
        z-index: 1000000;
        top: 2rem;
        left: 35vw;

        padding: 1.5rem;

        width: 30rem;
        height: 12rem;
        border-radius: 0.5rem;

        background-color: $green;
        color: black;

        &.closed{
            display: none;
        }

       .close{
            width: 1.5rem;

            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
       }

       .title{
           font-size: 1.1rem;
           font-weight: bold;
           text-align: center;
           color: $white;
       }

       .subtitle{
           color: $white;
           text-align: center;

           margin-top: 1rem;
           margin-bottom: 0.5rem;
       }

       .cadastrar-button{
            width: 13rem;
            height: 3rem;
            padding: 0.5rem;
            background-color: $white;
            
            text-align: center;
            color: $black;
            margin-top: 2rem;

            border: none;
            border-radius: 1rem;

            :hover{
                cursor: pointer;
            }
       }
   }

   .phone-validation-modal{
        width:  30rem;
        height: 20rem;

        background-color: $white;

        position: fixed;
        align-self: center;
        z-index: 1000000;
        top: 2rem;
        left: 35vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;

        padding: 1.5rem;

        .close{
            width: 1.5rem;

            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
       }
        
        .top {
            width:    100%;
            display: block;
            
            padding:       10px;
            border-radius: 5px;
            
            font-weight: 700;
            font-size: .8rem;
            
            margin: 0 0 05px;
            color:    $black;

            text-align: left;
        }
        

        

        .phone-input{
            width: 8rem;
            height: 3rem;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
            align-self: center;
        }
        //Remove as setas do input
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .actions {
            width: 20rem;

            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: space-between;

            .link  { padding: 30px 5px; font-size: .9rem; display: block; text-transform: uppercase; }
            button { padding: 5px 20px; }
        }


        .pinWarning{
            color: red;
        }

        .pin-warning-message{
            color: red;
            font-size: 1rem;
        }

    }

    #fillPhone-modal {
        width:  30rem;
        height: 20rem;

        background-color: $white;

        position: fixed;
        align-self: center;
        z-index: 1000000;
        top: 2rem;
        left: 35vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;

        padding: 1.5rem;

        input {
            font-weight: bold;

            padding: 0.3rem;
        }

        .close{
            width: 1.5rem;

            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
       }

       .phoneWarning{
            color: red;
        }

        .phone-warning-message{
            color: red;
            font-size: 1rem;
        }
    }
    .success-modal{
        width:  30rem;
        height: 16rem;

        background-color: $white;

        position: fixed;
        align-self: center;
        z-index: 1000000;
        top: 2rem;
        left: 35vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;

        padding: 1.5rem;

        .close{
            width: 1.5rem;

            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
       }

       .check{
           color: $green;
           font-size: 5rem;
       }
    }

    @include media-breakpoint-down(sm) {
        #container-modal {
            width: 22rem;
            height: 12.5rem;

            left: 8vw;

            .cadastrar-button{
                height: 2.5rem;
            }
        }

        .phone-validation-modal{
            left: 8vw;

            width:  22rem;
            height: 25rem;
        }

        #fillPhone-modal{
            left: 8vw;

            width:  22rem;
            height: 20rem;
        }

        .success-modal{
            left: 8vw;

            width:  22rem;
            height: 18rem;
        }
    }

    @include media-breakpoint-up(lg) {
        #warning-modal { .modal-box { width: 40%; .modal-body { p { width: 80%; }} }}
    }