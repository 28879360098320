@import '../../assets/sass/app.scss';

    .terms-modal {
        width:  100%;
        height: 100%;

        .modal-box {
            width:    70vw;
            height:   90vh;
            
            padding:         0;
            border-radius: 5px;

            position:  absolute;
            top: 50%; left: 50%;
            
            transform: translate(-50%, -50%);
            background-color: $silver;

            display:          flex;
            flex-direction: column;

            align-items:     center;
            justify-content: center;

            .close-modal {
                margin: 0 0 0 5px;
                color:     $white;
                
                font-size: 1.8rem;
                cursor:   pointer;

                position:   absolute;
                top: -30px; right: 0;

                @include transition;

                &:hover {
                    color: $green;
                }
            }
            .modal-body {
                width:  100%;
                height: 100%;

                overflow-y: auto;
                padding:    30px;

                h1 {
                    font-weight:  700;
                    font-size: 1.6rem;
        
                    width:        100%;
                }
                p {
                    font-weight: 400;
                    font-size: .9rem;
                    
                    color: $darkGray;
                    line-height: 1.6;
        
                    margin-left: 15px;
                }
                article {
                    width:  100%;
                    margin: 10px auto;
        
                    > h1 {
                        font-weight:  700;
                        font-size: 1.2rem;
                        
                        color:     $black;
                        line-height:    1;
        
                        margin:   15px 0;
                        text-align: left;
                    }
                    ul {
                        li {
                            font-weight: 400;
                            font-size: .9rem;
                            
                            color: $darkGray;
                            line-height: 1.6;
                        }
                    }
                    span {
                        display: inline-block;
                        margin-left:     25px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .terms-modal {
            .modal-box {
                width:    95vw !important;
                height:   90vh !important;
            }
        }
    }