@import '../../../../assets/sass/app.scss';


.payment-error {
  color: $red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}

.ant-switch-disabled {
  padding: 0 !important;
}

.payment {
  .coupon {
    padding: 0 !important;
    margin: 0 !important;
    border-top-width: 0 !important;
  }
}

.switch {
	background-color: white;
	display: flex;
	flex-direction: column;
	margin: 24px 0;
	.switch-container {
		background-color: white;
		border: 1px solid #E6E8EC;
		border-radius: 8px;
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: center;
		padding: 16px;
		justify-content: space-between;

		.switch-title {
			margin-right: 8px;
			font-weight: 600;
		}
	}
}

.banner-mus {
	background-color: #FFCC4D;
	width: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	gap: 8px;
	p {
		margin: 0;
		color: #0B5C3C;
		font-size: 18px;
		text-decoration: none;
	}
	strong {
		font-size: 18px;
		color: #0B5C3C;
		font-weight: 800;
	}
	.mus-arrow {
		text-decoration: underline;
		font-weight: 800;
	}
}

@media screen and (max-width: 1024px) {
	.banner-mus {
		p, strong {
			font-size: 14px;
		}
		.mus-arrow {
			text-align: center;
		}
	}
}

.cards-checkout {
  padding-bottom: 15px;

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    z-index: 10;
  }

  .card-session-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 15px 0;
  }

  .card-actions {
    display: flex;
    gap: 12px;
    align-items: center;
    #card-submit {
      padding: 5.5px 17px;
      border-radius: 4px;
      &.green {
        border-color: #48d785;
        background-color: #48d785;
        &:hover {
          background-color: #54d397;
          color: white;
        }
      }
    }
  }

  #card-selection {
    display: flex;
    flex-direction: column;
    gap: 9px;

    .checkout-card-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      background-color: white;
      border-radius: 8px;
      border: 1px solid #D9D9D9;
      cursor: pointer;
      gap: 8px;

      &.active {
        cursor: default;
        border-color: $green;
        .card-radio {
          background-color: $green;
        }
      }

      .card-info {
        display: flex;
        flex-direction: column;
      }

      .card-first {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .card {
        &-radio {
          width: 16px;
          height: 16px;
          border-radius: 50px;
          background-color: #E6E8EC;
        }

        &-session-container {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        &-detail {
          color: #23262F;
          font-size: 14px;
          font-weight: 500;
        }

        &-validity {
          color: #23262F;
          font-weight: 600;
          font-size: 16px;
        }

        &-current-state {
          color: #209869;
          text-align: center;
          height: 24px;
          &.withLink {
            font-size: 14px;
            cursor: pointer;
          }
        }

        &-brand-image {
          background: #F4F5F6;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 59px;
          height: 42px;
        }

        &-brand {
          text-transform: capitalize;
          font-weight: 600;
          font-size: 14px;
        }
      }

      label {
        font-size: 14px !important;
        font-weight: 400 !important;
        display: flex;
        align-items: center;
        margin-bottom: 0 !important;
        gap: 4px;
      }
    }
  }
}
