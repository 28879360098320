@import '../../assets/sass/app.scss';

.main-redirect {
    height: 100vh;
    background: $silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    max-width: 550px;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    background-color: $white;
    .title {
        text-align: center;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
    }
    .description {
        text-align: center;
        margin-bottom: 21px;
        font-size: 14px;
        line-height: 21px;
    }
}