@import '../../assets/sass/app.scss';

main#terms-and-conditions {
  padding: 40px 0;
  
  * {
    color: $black !important;
  }

  h1 {
    font-weight: 700;
    font-size: 1rem;

    width: 100%;
  }
  
  .MsoNormal {
    margin-bottom: 10px !important;
  }

  p {
    font-weight: 400;
    font-size: .9rem;

    line-height: 1.6;

    margin-left: 15px;
  }

  article {
    width: 100%;
    margin: 10px auto;

    > h1 {
      font-weight: 700;
      font-size: 1.2rem;
      
      line-height: 1;

      margin: 15px 0;
      text-align: left;
    }

    ul {
      li {
        font-weight: 400;
        font-size: .9rem;

        line-height: 1.6;
      }
    }

    span {
      display: inline-block;
      margin-left: 15px;
    }
  }
}

@include media-breakpoint-down(sm) {
  main#terms-and-conditions {
    width: 100%;
    padding: 120px 15px;
  }
}

@include media-breakpoint-up(lg) {
  main#terms-and-conditions {
    width: 70%;
    margin: 80px auto;
  }
}
