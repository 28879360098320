@import '../../assets/sass/app.scss';

    footer {
        width:   100%;

        background-color: $black;

        position: relative;
        bottom: 0; left: 0;

        .row {
            display:         flex;
            align-items:     center;
            justify-content: space-between;

            .logo {
                cursor: pointer;
                img { width: 50px; }
            }
            nav {
                ul {
                    width:  100%;
                    margin: 0 auto;

                    display:         flex;
                    align-items:     center;
                    justify-content: space-between;

                    padding:    0;
                    list-style: none;

                    li {
                        a {
                            padding: 10px;

                            font-weight: 400;
                            font-size: .8rem;

                            text-decoration: none;

                            @include transition;

                            &:hover {
                                color: $green;
                            }
                        }
                    }
                }
            }
            .social {
                width:    10%;
                display: flex;

                align-items:     center;
                justify-content: center;

                a { 
                    display:     block;
                    width: fit-content;

                    color:     $white;
                    margin:    0 5px;
                    
                    @include transition;
                    
                    &:hover { transform: translateY(-2px); }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 30px 15px;

            .row {
                flex-direction: column;

                nav {
                    width:   100%;
                    padding: 15px;
    
                    ul { 
                        flex-direction: column;
                        li { padding: 5px; a { color: $gray; }}
                    }
                }
                .actions { width: 100%; button { margin: 15px auto; }}
                .social  { margin-top: 15px; * { font-size: 2.4rem; }}
            }
        }

        @include media-breakpoint-up(lg) {
            padding: 10px;
            
            .row {
                flex-direction: row;

                nav {
                    width:   70%;
                    padding: 0 15px;
    
                    ul { 
                        flex-direction: row; 
                        li { padding: 5px; a { color: $silver; }}
                    }
                }
                .actions { width: 15%; }
                .social  { * { font-size: 1.8rem; }}
            }
        }
    }