@import '../../assets/sass/app.scss';

#section-total-prizes{
    .total-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0.5rem;

        width: 100%;

        padding: 5rem 0rem;

        span {
            margin: 0.2rem;
        }
        
        .total-prizes{
            font-size: 2rem;
            font-weight: bold;
            width: 100vw;
            text-align: center;

            margin: 0%;
            padding: 0%;
        }
        
        
        figure {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        
        
        .swiper-container {
            width: 100%;
            padding: 1rem 1rem;

           

            .swiper-button-next,.swiper-button-prev {
                color: black;
            }

            .swiper-slide {
                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
    
                .total-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    text-align: center;

                    width: 20rem;
    
                    border-radius: 0.3rem;
    
                    padding: 2rem;
    
                    img {
                        width: 2.5rem;
                        margin-bottom: 1rem;
                    }
    
                    &.mega-sena-da-virada { background-color: $megaSena;   color: $white; }
                    &.mega-sena           { background-color: $megaSena;   color: $white; }
                    &.timemania           { background-color: $timeMania;  color: $white; }
                    &.quina               { background-color: $quina;      color: $white; }
                    &.lotofacil           { background-color: $lotoFacil;  color: $white; }
                    &.lotomania           { background-color: $lotoMania;  color: $white; }
                    &.loteca              { background-color: $loteca;     color: $white; }
                    &.dupla-sena          { background-color: $duplaSena;  color: $white; }
                    &.dia-de-sorte        { background-color: $diaDeSorte; color: $white; }
                    &.super-sete          { background-color: $superSete; color: $white; }
                    &.mais-milionaria     { background-color: $maisMilionaria; color: $white; }


                    .total-game-prize {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }

                    .total-game-description {
                        font-size: 0.7rem;
                        font-weight: 500;
                    }

                    .total-game-type {
                        font-size: 1rem;
                        font-weight: 900;
                    }
                }
            }
        }

    }
}

@include media-breakpoint-down(sm) {
    #section-total-prizes{
        .total-container{
            .swiper-container{
                width: 100vw;
                padding: 0%;
                margin: 0%;
            }
        }
    }
}