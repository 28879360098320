@import '../../assets/sass/app.scss';

    section#section-about {
        width:   100%;
        padding: 50px 0;

        background-color: $blue;

        .row {
            figure {
                width:  30%;
                height: auto;

                overflow: hidden;
                margin:   0 auto;

                img {
                    width:  100%;
                    height: 100%;

                    object-fit:       cover;
                    object-position: center;

                    display: flex;
                }
            }
            .text {
                width: 60%;

                h1 {
                    font-weight:  700;
                    font-size: 1.8rem;
                    
                    color:  $white;
                    margin: 10px 0;
                }
                p {
                    font-weight: 300;
                    font-size: .9rem;
                    
                    color:    $white;
                    padding:   5px 0;
                    line-height: 1.4;
                }
                strong { 
                    display: block;
                    
                    color:  $white; 
                    margin: 10px 0;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .row {
                flex-flow:       column;
                align-items:     center;
                justify-content: center;

                figure { width: 80%; }
                .text  { width: 100%; padding: 0 15px; text-align: center; }
                button { margin: auto; }
            }
        }

        @include media-breakpoint-up(lg) {
            .row {
                align-items:     center;
                flex-flow:       row wrap;
                justify-content: space-between;

                figure { width: 30%; }
                .text  { width: 70%; }
            }
        }
    }