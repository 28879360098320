#cart-mobile > div {
  z-index: 99999999;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0px -3px 16px rgba(0, 0, 0, 0.25) !important;
}

[data-rsbs-header] {
  overflow: hidden;
}

#cart-mobile {
  .clean-cart-button {
    color: #209869;
    align-self: flex-end;
    font-size: 14px;
    cursor: pointer;
    border: none !important;
    background: none !important;
    transition: color .2s;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 0 !important;
    &:hover {
        color: #0f754e;
    }
    &:disabled {
        color: #b4b4b6 !important;
    }
  }
}
#cart-mobile-header {
  .header {
    display: grid;
    width: 100%;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    grid-template-columns: repeat(3, 1fr);
    .item-count {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    small {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      text-align: left;
      line-height: normal;
    }
    .timer {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
    .cart-total {
      padding: 10px 0;
      align-self: flex-end;
      .total {
        font-weight: 600;
        font-size: 16px;
        color: #23262F;
        width: 100%;
      }
    }
  }
}