@import '../../assets/sass/app.scss';

@media screen and (max-width: 1024px) {
  #cart {
    width: 100%;
  }
  
  .coupon {
    border-top-width: 0 !important;
    padding: 0 0 16px 0 !important;
  }

}

#checkout {
  .coupon-form {
    width: 100%;
  }
  
  .coupon {
    border-top-width: 0 !important;
    padding: 0 0 16px 0 !important;
  }
}

#cart {
    position: fixed;
    z-index: 9999;
}


#cart-mobile {
    .cart-div {
        max-height: 71vh !important;
    }
}

@media screen and (max-width: 425px) {
    .line .name {
        min-width: 60px !important;
    }
}

#cart, #cart-mobile {
    [data-rsbs-header] {
        box-shadow: 0px -3px 16px rgba(0, 0, 0, 0.25);
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }
    [data-rsbs-scroll] {
        margin: 10px 0;
        // display: flex;
        & > div {
            // height: 100%;
            // flex: 1;
            // display: flex;
            // flex-direction: column;
        }
    }
    
    .rs-drawer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cart-div > button {
            margin-bottom: 15px;
        }
    }
    .loading-cart {
        flex: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        background: #e5e5eac4;
        border-radius: 10px;
    }
    .rs-drawer-header {
        z-index: 3;
        #cart-title {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .clean-cart-button {
            color: #209869;
            font-size: 14px;
            cursor: pointer;
            border: none !important;
            background: none !important;
            transition: color .2s;
            box-shadow: none !important;
            padding: 0 !important;
            margin: 0 !important;
            line-height: 0 !important;
            &:hover {
                color: #0f754e;
            }
            &:disabled {
                color: #b4b4b6 !important;
            }
        }
    }
    
    .actions, main.cart-div {
        padding: 20px;
    }

    .actions {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #e5e5ea;
        padding-top: 16px;
        .cart-total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            small {
                font-size: 12px;
                font-weight: 500;
                color: #000;
            }
          
        }
    }
    
    main.cart-div {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        padding-bottom: 0;
        overflow: overlay !important;
        .cart-box {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 16px;
            flex: 1;
            z-index: 1;
            .empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                min-height: 60vh;
                overflow: hidden;
                gap: 8px;
                .empty-text {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .group {
                display: flex;
                gap: 8px;
                flex-direction: column;
                .product-group {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;
                    .product-group-name {
                        font-weight: 600;
                        font-size: 16px;
                        color: #000;
                        padding: 0;
                        margin: 0;
                    }
                }
                .item-group {
                    background: white;
                    border-radius: 10px;
                    padding: 8px 12px;
                    flex-direction: column;
                    display: flex;
                    gap: 16px;
                    .line {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        &.disabled {
                            cursor: not-allowed;
                            user-select: none;
                            .quantity span, .name {
                                opacity: 0.7;
                            }
                        }
                        .name {
                            font-size: 12px;
                            color: #000;
                            margin-bottom: 0;
                            min-width: 90px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .quantity {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 12px;
                            span {
                                min-width: 30px;
                                text-align: center;
                            }
                            button {
                                &:disabled {
                                    opacity: 0.7;
                                    cursor: not-allowed;
                                    box-shadow: none !important;
                                }
                                width: 32px;
                                height: 32px;
                                padding: 0;
                                border-radius: 100px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #F4F5F6;
                                border: none;
                            }
                        }
                        .total {
                            font-weight: 600;
                            font-size: 14px;
                            color: #23262F;
                            &.with-error {
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
}

.coupon {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-top: 1px solid #e5e5ea;

  small {
    margin-top: 2px;
    color: #ff0000;
  }

  .coupon-actions {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    justify-content: space-between;
    
    .button-group {
      width: 100%;
    }
    
    .input-group {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
      .remove-coupon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    input {
      width: 100%;
      padding: 8px;
      border: 1px solid #e5e5ea;
      border-radius: 8px;
      font-size: 14px;
      color: #000;
      &::placeholder {
        color: #b4b4b6;
      }
    }
    button {
      padding: 8px 16px;
      border: none;
      border-radius: 8px;
      background: #209869;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      transition: background .2s;
      &:hover {
        background: #0f754e;
      }
    }
  }
}

.subtotal {
  font-weight: 600;
  font-size: 14px;
  color: #757e8d;
  text-decoration: line-through;
  &.with-error {
    color: $red;
  }
}

.total-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.total {
  font-weight: 600;
  font-size: 14px;
  color: #23262F;
}