#terms-and-conditions {
    .container {
        display: flex;
        flex-direction: column;
        gap: 20px 0;
        justify-content: center;
        align-items: center;
    }
    .row {
        .term-date {
            font-weight: 600;
            text-align: center;
            width: 100%;
            margin: 10px 0 30px 0;
        }
        .content {
            max-height: 600px;
            overflow-y: auto;
        }
        span {
            font-weight: bold;
        }
    }
}
