@import '../../assets/sass/app.scss';

    main#jackpots {
        height: auto;
        
        > .title {
            padding: 0;
            border-bottom: 1px solid $lightGray;

            .centered{
                align-items: center;
                justify-content: center;
            }
            
            h1 {
                width:  100%;
                margin: 80px 1rem 40px 1rem;
                
                font-weight: 900;
                font-size: 2.4rem;
                
                text-align: center;
            }

            .styled-button{
                background-color: #7069E7;
                color: $white !important;
                align-self: center;

                margin-bottom: 20px;

                &.link{
                    color: $white;
                }
            }
        }
    }