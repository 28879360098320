.timer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.text-countdown {
  font-size: 16px;
  font-weight: 700;
  color: #209869;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 4px;
  height: 34px;
  padding: 0 8px !important;
  .label {
    font-weight: 400;
    text-align: center;
  }
}