@import '../../assets/sass/app.scss';

main#checkout {
    background-color: #F4F5F6;
    display:   flex;
    flex-direction: column;
    align-items:     center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    padding-top: 0 !important;

    #steps {
        padding: 24px 0;
        display:   flex;
        flex-direction: column;
        align-items:     center;
        justify-content: center;
        max-width: 800px;
        width: 100%;
    }

    .loading {
        width:  100%;
        height: 100%;

        position: absolute;
        top:  0;  left:  0;

        background-color: rgba(255, 255, 255, .5);

        display:          flex;
        flex-direction: column;

        align-items:     center;
        justify-content: center;

        .MuiCircularProgress-colorPrimary { color: $green; }
    }

    figure {
        @include cover(250px, auto);
        margin: 0 auto 25px auto;
        cursor: pointer;
    }

    section[id^=checkout-step-] {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        background:white;
        width: 100%;
        border-radius: 16px;
        &.loading {
            min-height: 800px !important;
        }
        position: relative;

        .empty-cart {
            display: flex;
            flex-direction: column !important;
            align-items: center;
            justify-content: center;
            gap: 16px;
            .empty-text {
                font-size: 24px;
                font-weight: 600;
                color: #353945;
            }
            .empty-description {
              font-size: 14px;
              color: #353945;
              margin-bottom: 8px;
              max-width: 400px;
              text-align: center;
            }
        }
        h1.title {
            font-weight: 600;
            font-size: 24px;
            color: $black;
            text-align: center;
            margin-bottom: 52px;
        }
        .subtitle {
            font-weight: 500;
            font-size: 22px;
            color: $black;
            text-align: center;
        }
    }

    section#checkout-step-box {
        min-height: 500px;
        padding: 32px;
    }

    section#checkout-step-terms {
	    position: relative;
	    flex: 1;
        .container-text {
            .content {
                min-height: 300px;
                max-height: 300px;
                overflow-y: auto;
                padding: 10px;
            }
            width: 100%;
            background: #F4F5F6 !important;
            border-radius: 8px;
            padding: 12px;
            margin-bottom: 16px;
        }
        .actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px !important;
        }
    }

    section#checkout-step-payment {
        display: flex;
        flex-direction: column;
        .checkbox-name .link {
            color: #209869 !important;
            text-decoration: underline;
        }
        #change-method {
            color: #209869;
            font-size: 16px;
            width: 100%;
            align-self: center;
            cursor: pointer;
            border: none !important;
            background: none !important;
            transition: color .2s;
            box-shadow: none !important;
            padding: 0 !important;
            margin: 0 !important;
            line-height: 0 !important;
            &:hover {
                color: #0f754e;
            }
            &:disabled {
                color: #b4b4b6 !important;
            }
        }
        .payment-box {
            width:   100%;
            padding: 15px 15px 1px 15px;

            background-color: #F4F5F6;
            position:         relative;
            border-bottom-left-radius:    10px;
            border-bottom-right-radius:    10px;
            color: #141416;

            strong {
                font-weight: 500 !important;
                font-size: 14px !important;
                color: #141416;
            }

            .title {
                display: flex;
                align-items: center;
                gap: 4px;
                margin-bottom: 10px;
                h1 {
                    width: fit-content;
                    font-size: 1.2rem !important;
                    margin-bottom: 0px;
                }
            }

            p {
                font-weight: 500 !important;
                font-size: 14px !important;
                color: #141416 !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            label {
                font-weight: 700;
                font-size: .7rem;
                color:    $black;
            }
            small {
                font-size: .6rem;
                line-height:  .8;

                padding:   6px 0;
                display:    flex;

                text-align: left !important;
            }
            .loading {
                width:  100%;
                height: 100%;

                position: absolute;
                top:  0; right:  0;

                background-color: rgba(255, 255, 255, .8);
                display:            flex;

                flex-direction:  column;
                align-items:     center;
                justify-content: center;

                svg { color: $green; }
            }
            .form-group             { padding:   0 5px; }
            .form-control           { font-size: .9rem; }
            .srv-validation-message { font-size: .6rem; line-height: .9; }
        }
        .pay-options {
            margin: 20px 0 40px;

            .rs-panel-group {
                display:            flex;
                flex-direction:  column;
                gap: 12px;
                .rs-panel {
                    .rs-panel-header[aria-expanded="true"] button {
                        border-bottom-left-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                    }
                    .rs-panel-body {
                        padding: 0;
                    }
                    .rs-icon {
                        display: none;
                    }
                    &:before {
                        content: none;
                    }
                    .rs-panel-header {
                        padding: 0;
                    }
                }
            }
        }

        .terms-accept {
          margin: 32px 0 24px 0 !important;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .label {
            font-weight: 600       !important;
            font-size:   24px      !important;

            color:    #141416 !important;
            margin:   0 0 32px !important;
            text-align: left !important;
            display: flex;
            gap: 8px;
            align-items: center;
        }
        .payment {
            width:   100%;

            .methods-error{
                text-align: center;
                margin: 1rem;
                color: red;
            }

            .coupon-label {
                color: #353945;
                font-size: 12px;
                font-weight: 700;
            }

            .credit {
                padding: 15px 0;
                border-bottom: 1px solid $lightGray;

                .option {
                    > span { padding: 1px 5px; color: $green; }
                    .MuiFormControlLabel-label {
                        font-weight: 400;
                        font-size: .9rem;
                        color:    $black;
                    }
                }
                .field {
                    padding: 10px 0;

                    label {
                        font-weight: 300;
                        font-size: .9rem;
                        padding:   5px 0;
                    }
                }
            }
            .line {
                width:     100%;
                padding:  3px 0;

                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: space-between;

                font-weight: 300;
                font-size: .9rem;
                color:     $gray;
            }
            .sub-total {
                // margin-top: 49px;
            }
            .coupon {
                margin: 49px 0;
            }
            .totals, .sub-total {
                width:     100%;
                padding: 10px 0;

                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: space-between;

                font-weight: 600;
                font-size:  24px;
                color:    #141416;
                margin: 43px 0 31px 0;
                .total {
                    color: #23262F;
                }
            }
            .disclaymer {
                width:   95%;
                margin:  20px auto 0;
                padding: 20px 10px;

                display:   flex;
                flex-flow: row wrap;

                align-items:     center;
                justify-content: space-between;

                border-top: 1px solid $silver;

                svg {
                    margin-right: 10px;
                    font-size:  2.6rem;
                    color:       $blue;
                }
                p {
                    flex:   1;
                    margin: 0;

                    font-weight: 300;
                    font-size: .9rem;
                    text-align: left;
                }
            }
            .select-payment {
                width:   100%;
                padding: 16px;

                border:          0;
                border-radius: 5px;

                background-color: white;
                border: 1px solid #D9D9D9;

                display:       flex;
                gap: 5px;
                flex-direction: row;

                align-items:     center;

                font-size: 16px;

                font-weight: 600;
                color:    $green;

                .radio {
                    width: 20px;
                    height: 20px;
                    border-radius: 100px;
                    background: white;
                    border: 2px solid $gray;
                    margin-right: 8px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.active {
                        border: 3px solid $white !important;
                        .radio-button {
                            width: 100%;
                            height: 100%;
                            border-radius: 100px;
                            padding: 5px;
                            background: $green;
                        }
                    }
                }

                &.active {
                    background-color: $green;
                    border-color: $green;
                    color:    $white !important;
                }

                &:disabled {
                    background-color: #B1B5C4;
                    box-shadow: none !important;
                    &:hover {
                        background-color: #B1B5C4;
                    }
                }

                cursor: pointer;
                @include transition;

                img {
                    width:       25px;
                }

                &:not(.active):hover {
                    background-color: rgba(32, 152, 105, 0.10);
                }
            }
        }
    }

    section#checkout-step-success {
        flex: 1;
        #checkout-success {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h1 {
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 40px;
                font-size: 16px;
                color: #000;
                text-align: center;
                width: 100%;
            }

            .actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 12px;
                margin-top: 16px;
            }

            .subtitle {
                font-size: 20px;
                color: #000;
                text-align: center;
                width: 100%;
                margin: 20px 0;
            }

            .info-title {
                font-size: 16px;
                font-weight: 600;
                color: #353945;
            }

            .status-label {
              display: flex;
              align-items: center;
              gap: 6px;
              border: 1px solid #c5c6c7;
              padding: 4px 8px;
              border-radius: 100px;
              .info-badge-status {
                width: 12px;
                height: 12px;

                flex-shrink: 0;
                border-radius: 100px;
              }
            }

            .info-value {
                font-size: 14px;
                font-weight: 500;
                color: #353945;
                &.text-right {
                    text-align: right;
                    width: 100%;
                }
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px 20px 20px 0;
                width: 100%;
                .info-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                }
            }

            .order-items {
                display: flex;
                flex-direction: column;
                & div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }
            }



            .barcode-box {
                display: flex;
                flex-direction: column;
                border: 1px solid #E5E5E5;
                padding: 16px;
                border-radius: 10px;
                gap: 12px;
                p {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    width: fit-content;
                    align-self: center;
                    &:active, &:focus {
                        text-decoration: none !important;
                    }
                    &:hover {
                        color: #209869;
                    }
                }
            }
        }

        #pix-countdown {
            max-width: 100%;
            .expiration-qrcode {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }

            #countdown {
                svg {
                    --percentage: 0;
                    width: 150px;
                    height: 150px;
                    transform: rotate(-90deg);

                    circle {
                        stroke-dasharray: 618;
                        stroke-dashoffset: 618;
                        stroke-width: 10;
                        fill: none;

                        &:nth-child(1) {
                            stroke-dashoffset: 0;
                        }
                        &:nth-child(2) {
                            transition: all 0.3s;
                            stroke-dashoffset: calc(618 - (618 * var(--percentage)) / 100);
                            stroke-linecap: round;
                            stroke: $green;
                        }
                    }
                    .label {
                        font-size: 32px;
                        color: $green;
                    }
                }
            }

            .expiration-time {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                &.label {
                    .timer {
                        padding: 5px 10px !important;
                        min-width: 120px !important;
                    }
                }
                .label {
                    font-weight: 600;
                    font-size: 16px;
                    color: #000;
                    margin-top: 10px;
                    max-width: 170px;
                    text-align: center;
                }
                .timer {
                    background: #f1efef !important;
                    padding: 10px 20px;
                    margin: 5px 0;
                    border-radius: 100px;
                    display: flex;
                    min-width: 80px;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                }
            }

            .warn {
                margin: 15px 0;
                width: 100%;
            }

            .pix-code {
                width: 100%;
                .pix-label {
                    color: #FFF;
                    background: $green;
                    font-size: 12px;
                    font-weight: 700;
                    text-align: center;
                    display: block;
                    border-radius: 10px;
                    padding: 8px;
                    margin: 16px 0 10px 0;
                    border: 1px solid $green;
                    &.copied {
                        background: $white;
                        color: $green;
                    }
                }
                #pix-code-input {
                    padding: 10px;
                    background-color: #f1efef;
                    border-radius: 10px;
                    display: flex;
                    margin-top: 4px;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    #copy-code {
                        padding: 5px 15px !important;
                    }
                    span {
                        display: inherit;
                        white-space:   nowrap;
                        overflow:      hidden;
                        text-overflow: ellipsis;
                    }
                }
                .text-countdown {
                    margin: 12px 0;
                }
            }
        }
    }

    #cart-content-items {
        width: 100%;
        .products {
            width: 100%;
            .group-list {
                display: flex;
                flex-direction: column;
                gap: 16px;
                .group {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .product-group {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-bottom: 8px;
                        .product-group-name {
                            font-weight: 600;
                            font-size: 16px;
                            color: #000;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .item-group {
                        background: #F4F5F6;
                        border-radius: 10px;
                        padding: 8px 12px;
                        flex-direction: column;
                        display: flex;
                        gap: 16px;
                        .line {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 16px;
                            &.disabled {
                                cursor: not-allowed;
                                user-select: none;
                                .quantity span, .name {
                                    opacity: 0.7;
                                }
                            }
                            .name {
                                font-size: 12px;
                                color: #000;
                                margin-bottom: 0;
                                min-width: 90px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .quantity {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 12px;
                                .qty {
                                    text-align: center;
                                    font-size: 14px;
                                }
                                span {
                                    min-width: 30px;
                                    text-align: center;
                                }
                                button {
                                    &:disabled {
                                        opacity: 0.7;
                                        cursor: not-allowed;
                                        box-shadow: none !important;
                                    }
                                    width: 32px;
                                    height: 32px;
                                    padding: 0;
                                    border-radius: 100px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #F4F5F6;
                                    border: none;
                                }
                            }
                            .subtotal {
                                font-weight: 600;
                                font-size: 14px;
                                color: #23262F;
                                min-width: 110px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #checkout {
	      section#checkout-step-success {
		      .status-label.self-start {
			      align-self: flex-start !important;
		      }
	      }
        section#checkout-step-box {
            padding: 24px 16px !important;
            min-height: 100vh !important;
            border-radius: 0 !important;
            &.loading {
                min-height: 300px !important;
            }
        }

        #steps {
            max-width: 1024px !important;
            border-radius: 0 !important;
            padding: 0 !important;
        }

        figure {
            display: none;
        }

        .expiration-qrcode {
            flex-direction: column;
        }

        .info {
            flex-direction: column;
            gap: 16px !important;
            margin: 0 !important;
            width: 100% !important;
            align-items: flex-start !important;
            margin-bottom: 12px !important;
            .info-text {
                gap: 6px !important;
                .info-value, .info-title {
                    text-align: left !important;
                }
            }
        }
    }
}
