@import '../../assets/sass/app.scss';

    #warning-modal {
        width:  100%;
        height: 100%;

        position: absolute;
        top:  0;  left:  0;

        background-color: rgba(0, 0, 0, .3);
        z-index: 80;

        .modal-box {
            padding: 30px 15px;

            position:  absolute;
            top: 50%; left: 50%;
            
            transform: translate(-50%, -50%);
            background-color: $silver;

            display:          flex;
            flex-direction: column;

            align-items:     center;
            justify-content: center;

            border-radius: 5px;
            overflow:   hidden;

            .modal-body {
                overflow-x: hidden;
                overflow-y:   auto;

                width: 100%;
                height: 95%;

                display:        flex;
                flex-direction: column;

                align-items:     center;
                justify-content: center;

                img {
                    width:  60px;
                    margin: 0 auto 15px;
                }
                h1 {
                    font-weight:    900;
                    font-size:   1.4rem;
                    padding:     15px 0;
                }
                p {
                    margin: 5px auto; 

                    font-weight:   300;
                    font-size:    1rem;
                    text-align: center;
                }
                .loading {
                    width:  100%;
                    height: 100%;
                    
                    position: absolute;
                    top:  0;  left:  0;
                    
                    background-color: rgba(241, 239, 239, .6);
                    
                    z-index:   10;
                    margin:     0;
                    padding: 50px;
    
                    display:        flex;
                    flex-direction: column;
    
                    align-items:     center;
                    justify-content: center;
    
                    svg {
                        color:   $green;
                        font-size: 5rem;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        #warning-modal { .modal-box { width: 90%; .modal-body { p { width: 100%; }}}}
    }

    @include media-breakpoint-up(lg) {
        #warning-modal { .modal-box { width: 40%; .modal-body { p { width: 80%; }} }}
    }