@import '../../assets/sass/app.scss';

.banner-mus {
	background-color: #FFCC4D;
	width: 100%;
	padding: 16px;
	justify-content: center;
	align-items: center;
	display: flex;
	gap: 8px;
	p {
		margin: 0;
		color: #0B5C3C;
		font-size: 22px;
		text-decoration: none;
	}
	strong {
		font-size: 22px;
		color: #0B5C3C;
		font-weight: 800;
	}
	.mus-arrow {
		text-decoration: underline;
		font-weight: 800;
	}
}

header#top-menu {
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;

	z-index: 100;

	@include transition;

	#top-bar-desktop {
		max-height: 90px;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);

		background-color: $white;
		@include transition;

		.row {
			display: flex;
			flex-direction: row;

			align-items: center;
			justify-content: space-between;

			position: relative;

			figure {
				width: 200px;
				height: 98px;

				margin: 5px;

				overflow: hidden;
				display: block;

				position: relative;
				@include transition;
				cursor: pointer;

				img {
					display: flex;

					width: 100%;
					height: 100%;

					object-fit: cover;
					object-position: center;

					position: absolute;
					opacity: 1;

					transition: opacity .5s linear;
					@include transition;

					&.hidden {
						opacity: 0;
					}
				}

			}

			nav {
				width: 40%;

				ul {
					display: flex;
					flex-direction: row;

					align-items: center;
					justify-content: space-between;

					list-style: none;
					margin: 0 auto;
					padding: 0;

					li {
						a {
							padding: 7px;

							font-weight: 400;
							font-size: .9rem;

							color: $black;
							cursor: pointer;

							@include transition;
							text-decoration: none;

							&:hover {
								color: $blue;
							}

							&.active {
								color: $blue;
							}
						}
					}
				}
			}

			> div {
				min-width: 22%;
				display: flex;

				flex-direction: row;

				align-items: center;
				justify-content: center;

				button {
					margin: 0 5px;
				}

				.cart {
					position: relative;
					cursor: pointer;
					margin: 0 15px;

					svg {
						font-size: 1.8rem;
						color: $black;
						@include transition;
					}

					.quantity {
						width: 18px;
						height: 18px;

						display: flex;
						flex-direction: column;

						align-items: center;
						justify-content: space-around;

						background-color: $green;
						border-radius: 100%;

						font-size: .7rem;
						color: $white;

						position: absolute;
						top: -5px;
						right: -5px;
					}

					&:hover {
						svg {
							color: $blue;
						}
					}
				}
			}
		}

		&.small {
			height: 50px;

			.row {
				figure {
					width: 40px;
					height: 40px;

					img {
						object-fit: contain;
					}
				}

				nav {
					ul {
						margin: 0;
					}
				}

				> div {
					button {
						padding: 5px 20px;
					}
				}
			}
		}
	}

	#top-bar-mobile {
		width: 100vw;
		height: 90px;

		background-color: $white;
		padding: 5px 15px;

		.row {
			display: flex;
			flex-direction: row;

			align-items: flex-start;
			justify-content: space-between;

			figure {
				width: 50%;
				height: auto;

				margin: 10px 0 0;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: center;
					display: block;
				}
			}

			#sandwich {
				margin-top: 30px;

				height: fit-content;
				z-index: 140;

				cursor: pointer;
				position: relative;

				.bar-one, .bar-two, .bar-three {
					width: 30px;
					height: 3px;

					margin: 3px 15px 0;
					border-radius: 15px;

					background-color: $black;
					transition: 0.4s;
				}

				&.change .bar-one {
					-webkit-transform: rotate(-45deg) translate(-8px, 0px);
					transform: rotate(-45deg) translate(-8px, 0px);

					background-color: $white;
					border-radius: 15px;
				}

				&.change .bar-two {
					opacity: 0;
					color: $white;
				}

				&.change .bar-three {
					-webkit-transform: rotate(45deg) translate(-8px, -0px);
					transform: rotate(45deg) translate(-8px, -0px);
					background-color: $white;
					border-radius: 15px;
				}
			}

			.cart {
				position: relative;
				cursor: pointer;
				margin: 30px 15px 0;

				svg {
					font-size: 1.8rem;
					color: $black;
					@include transition;
				}

				.quantity {
					width: 18px;
					height: 18px;

					display: flex;
					flex-direction: column;

					align-items: center;
					justify-content: space-around;

					background-color: $green;
					border-radius: 100%;

					font-size: .7rem;
					color: $white;

					position: absolute;
					top: -5px;
					right: -5px;
				}

				&:hover {
					svg {
						color: $blue;
					}
				}
			}

		}
	}

	#menu-mobile {
		width: 100vw;
		height: 100vh;

		background-color: $blue;

		position: fixed;
		overflow-x: hidden;
		overflow-y: auto;

		top: 0;
		left: 0;
		z-index: 120;

		&.opened {
			transform: translateX(0);
		}

		&.closed {
			transform: translateX(-100%);
			visibility: hidden !important;
			pointer-events: none !important;
		}

		nav {
			width: 100%;
			padding-top: 30px;
			padding-left: 20px;

			ul {
				list-style: none;
				padding: 0;

				li {
					margin-bottom: 30px;

					a {
						display: block;

						color: $white;
						font-weight: 700;

						font-size: 1rem;
					}
				}
			}
		}

		.social {
			width: 100%;
			display: flex;
			margin-left: 20px;

			a {
				color: #FFf;

				svg {
					font-size: 32px !important;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		max-height: 80px;
		#top-bar-desktop {
			display: none;
		}
		#top-bar-mobile {
			display: flex;
			max-height: 80px;
		}
	}

	@include media-breakpoint-up(lg) {
		max-height: 100px;
		#top-bar-desktop {
			display: block;
		}
		#top-bar-mobile {
			display: none;
			max-height: 100px;
		}
	}
}

@media screen and (max-width: 1024px) {
	#top-bar-mobile .cart {
		visibility: hidden;
		pointer-events: none;
	}

	.banner-mus {
		p, strong {
			font-size: 14px;
		}
		.mus-arrow {
			text-align: center;
		}
	}
}
